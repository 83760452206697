import { createSlice } from "@reduxjs/toolkit";
// import { history } from '../../utils';
import { getUserCourses } from "../../services";
import { setIsLoading, setMessage } from "./app.slice";

export const userCoursesSlice = createSlice({
  name: "userCourses",
  initialState: [],
  reducers: {
    setUserCourses: (state, action) => action.payload,
    clearUserCourses: () => [],
  },
});

export const { setUserCourses, clearUserCourses } = userCoursesSlice.actions;

export const allUserCourses = () => {
  return (dispatch) => {
    dispatch(setIsLoading(true));
    getUserCourses()
      .then((userCourses) => {
        dispatch(setUserCourses(userCourses));
      })
      .catch((err) => {
        if (err.response.status === 401) return;

        dispatch(
          setMessage({
            type: "error",
            message: "No fue posible obtener los cursos, porfavor inténtelo nuevamente",
          })
        );
      })
      .finally(() => dispatch(setIsLoading(false)));
  };
};

export const updateUserCourses = () => {
  return (dispatch) => {
    getUserCourses()
      .then((userCourses) => {
        dispatch(setUserCourses(userCourses));
      })
      .catch((err) => {
        if (err.response.status === 401) return;
      })
  };
};

export default userCoursesSlice.reducer;
