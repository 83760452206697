import React from 'react';
import { useCourses, usePagination } from '../../../hooks';
import { Loader, Pagination } from '../../../components';
import { imageUrl } from '../../../utils';
import { CourseCard } from './components';

export const Service = () => {
  const { loading, courses } = useCourses();
  const { page, pages, startIndex, lastIndex, handleChangePage, paginationRange, contentRef } =
    usePagination({
      elementsPerPage: 9,
      totalElements: courses.length,
    });

  return (
    <>
      <div className='main-header anim' style={{ '--delay': '0s' }}>
        Cursos
      </div>

      {loading ? (
        <Loader delay={0} />
      ) : (
        <div className='col-lg-9 col-md-9 col-sm-12'>
          <div ref={contentRef} className='row'>
            {courses.slice(startIndex, lastIndex).map((item) => (
              <div key={item.id} className='col-md-6 col-lg-4 col-sm-6 m-b30'>
                <CourseCard item={item} imageUrl={imageUrl} />
              </div>
            ))}

            {pages > 1 && (
              <Pagination
                paginationRange={paginationRange()}
                currentPage={page}
                pages={pages}
                handleChangePage={handleChangePage}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
