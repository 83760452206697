import React from "react";
import { Spinner } from "react-bootstrap";
import { useModuleClass } from "../hooks/useModuleClass";
import { PdfIcon } from "../../../../components/Icons/PdfIcon";

export function ModuleClass({
  previousModuleQuizAdvance,
  prevCourseModule,
  courseModule,
  moduleClass,
  moduleIndex,
  moduleClassIndex,
}) {
  const { loadingItemAdvance, toggleClass, isUnlocked, handleClickVideo, handleClickSlide } = useModuleClass({
    previousModuleQuizAdvance,
    prevCourseModule,
    courseModule,
    moduleClass,
    moduleIndex,
    moduleClassIndex,
  });

  const isDisabled = loadingItemAdvance?.loading && loadingItemAdvance?.sectionId === courseModule?.id && loadingItemAdvance?.lessonId === moduleClass?.id;

  return (
    <div className="challenge-item-content anim flag-last" style={{ "--delay": ".4s" }}>
      {isUnlocked() && <div className="challenge-item-content m-0 item-locked"></div>}
      <div className="row">
        <div className="col-sm-7 program-item-title">
          <div className="program-item-title-content">
            <i className={toggleClass()} />
            <b className="challenge-title">{moduleClass.title}</b>
          </div>
        </div>
        <div className="col-sm-5 program-item-col text-right">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              {moduleClass?.slide && (
                <button disabled={isDisabled} className='btn' onClick={handleClickSlide}>
                {isDisabled ? (
                  <Spinner animation='border' variant='' className='m-auto' />
                ) : (
                  <i className='fa fa-folder-open' />
                )}
              </button>
              )}
            </li>
            <li className="list-inline-item">
              {moduleClass?.video && (
                <button disabled={isDisabled} className="btn bg-secondary text-black" onClick={handleClickVideo}>
                  {isDisabled ? (
                    <Spinner size='sm' animation='border' className='m-auto' />
                  ) : (
                    <i className='fa-solid fa-gamepad' />
                  )}
                </button>
              )}
            </li>
          </ul>
        </div>
        {moduleClass?.documents && moduleClass.documents.length > 0 && (
          <div className="col-sm-5 program-item-col mt-3 w-100 class-material-wrapper">
            {moduleClass.documents.map((document) => (
              <div key={document.id} className="rounded py-2 px-3 class-material-item">
                <a download href={document.url}>
                  <PdfIcon size={20} /> <span>{document.file_name_document}</span>
                </a>
              </div>
            ))}
          </div>
        )}
        {/* <div className="col-sm-5 program-item-col mt-3 w-100 class-material-wrapper">
          <div className="rounded py-2 px-3 class-material-item">
            <PdfIcon size={20} /> <span>Nombre del archivo.pdf</span>
          </div>
          <div className="rounded py-2 px-3 class-material-item">
            <PdfIcon size={20} /> <span>Nombre del archivo.pdf</span>
          </div>
        </div> */}
      </div>
    </div>
  );
}
