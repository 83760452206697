import { Accordion } from "react-bootstrap";

export const FaqItem = ({ key, title, description }) => {
  return (
    <div className="row program-item-container">
      <div className="col-sm-12 program-item-content flag-challenges">
        <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
          <div className="progress-bar-perc" />
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Accordion.Item eventKey={key}>
              <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                <div className="row">
                  <div className="col-sm-7 program-item-title-container program-item-title">
                    <div className="program-item-label">
                      <Accordion.Header className="stage-title">{title}</Accordion.Header>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Item>
            <div className="row challenges-container">
              <div className="col-sm-11 offset-sm-1 ">
                <Accordion.Collapse eventKey={key}>
                  <div className="row challenge-list">
                    <div className="col-sm-12 challenge-item-container">
                      <div className="faq-item-content anim flag-last" style={{ "--delay": ".4s" }}>
                        <div className="row">
                          <div className="program-item-col text-right">{description}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
