import { createSlice } from "@reduxjs/toolkit";

const courseSlice = createSlice({
  name: "course",
  initialState: {
    course: {},
    courseAdvance: {},
  },
  reducers: {
    setCourse: (state, action) => action.payload,
    updateCourseAdvance: (state, action) => ({
      ...state,
      courseAdvance: action.payload,
    }),
    clearCourse: () => ({
      course: {},
      courseAdvance: {},
    }),
  },
});

export const { setCourse, updateCourseAdvance, clearCourse } = courseSlice.actions;
export default courseSlice.reducer;
