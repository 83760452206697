import throttle from "lodash.throttle";
import { configureStore } from "@reduxjs/toolkit";
import { app, user, userCourses, courseAdvance, course, cart } from "./slices";
import { getStateApp, saveStateApp } from "../utils";

const persistedState = getStateApp();

export const store = configureStore({
  reducer: {
    app,
    user,
    userCourses,
    courseAdvance,
    course,
    cart,
  },
  preloadedState: persistedState,
});

store.subscribe(
  throttle(() => {
    saveStateApp(store.getState());
  })
);
