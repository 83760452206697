import React from "react";
import { AvanceDashboard, CalificacionDashboard, Certificacion, CourseCounter } from "../../../../components";

export function CourseProgress({ user, course, courseAdvance }) {
  return (
    <div className="row progress-widget flag-loaded">
      <div className="col-sm-12">
        <div className="row">
          <div className="course-advance col-sm-6 col-md-4 col-lg-4">
            <AvanceDashboard percAvan={courseAdvance?.percentage_course} />
          </div>

          <div className="course-advance col-sm-6 col-md-4 col-lg-4">
            <CalificacionDashboard
              showAprovedExams
              percCal={courseAdvance?.qualification_course}
              approved={courseAdvance?.total_exams_complete}
              total={courseAdvance?.total_exams}
            />
          </div>

          <div className="course-advance col-sm-6 col-md-4 col-lg-4">
            <CourseCounter courseId={course?.id || 0} />
          </div>

          <div className="certificate-advance col-sm-12 col-md-5 col-lg-4">
            <Certificacion
              user={user}
              course={course}
              percAvan={courseAdvance?.percentage_course}
              percCal={courseAdvance?.qualification_course}
              attemps={courseAdvance?.attemps}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
