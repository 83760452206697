import axios from "./axios.service";

export const getUserCourseTimeSpent = (course_id) => {
  return axios.get(`/counter/course/${course_id}`).then((res) => res.data);
};

export const sendCourseTimeSpentByUser = (courseId, time) => {
  return axios.post(`/counter/course/${courseId}`, {
    hours: time.hours,
    minutes: time.minutes,
  });
};
