import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setCourse, setIsLoading, setMessage } from "../store/slices";
import { useDispatch, useSelector } from "react-redux";
import { getCourse, getCourseAdvance } from "../services";
import { ROLES } from "../utils";

export function useCourse({ user, isProtected, userAdvance = true }) {
  const { idCourse } = useParams();
  const loading = useSelector((state) => state.app.loading);
  const { userCourses, course: { course, courseAdvance } } = useSelector((state) => state);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const fetchCourse = async () => {
      if (!idCourse) return

      try {
        if (user && user.roleId === ROLES.user && isProtected) {
          const userCourse = userCourses.find(
            (userCourse) => userCourse.course_id === Number(idCourse)
          );

          if (!userCourse || userCourse?.is_active === false) {
            dispatch(
              setMessage({
                type: "error",
                message: "No tienes acceso a este recurso",
              })
            );
            navigate("/");
          }
        }

        dispatch(setIsLoading(true));

        const [fetchCourse, courseAdvance] = userAdvance
          ? await Promise.all([getCourse(idCourse), getCourseAdvance(idCourse)])
          : await Promise.all([getCourse(idCourse)]);

        if (fetchCourse) {
          if (isMounted) {
            dispatch(setCourse({ course: fetchCourse || {}, courseAdvance: courseAdvance || {} }));
          };
        } else {
          dispatch(
            setMessage({
              type: "error",
              message: "El curso no existe en la base de datos",
            })
          );
          navigate("/");
        }
      } catch (err) {
        dispatch(
          setMessage({
            type: "error",
            message: "No fue posible obtener los recursos, por favor inténtelo nuevamente",
          })
        );

        setError(true);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchCourse();

    return () => (isMounted = false);
  }, [/* userCourses, */ idCourse, dispatch, navigate, user, isProtected, userAdvance]);

  return { id: idCourse, loading, error, course, courseAdvance };
}
