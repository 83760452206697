import React from 'react'

export function Events() {
  return (
    <div className='col-md-8 pe-2 actual-banner dashboard-banner-container '>
      <div className='content h-100 anim' style={{ '--delay': '.7s' }}>
        <div className='background' />
        <div className='data'>
          <div className='data-title'>
            <p className='headline d-inline-block'>#Eventos</p>
            <span>
              ¡Descubre todos nuestros eventos! No pierdas la
              oportunidad de participar.
            </span>
          </div>

          <div className='data-subtitle'>
            <small>
              Sigue aprendiendo con contenido creado por cientos de
              profesionales.
            </small>
          </div>
        </div>

        <div className='btn-container mb-2'>
          <a
            href='https://capacitacion-landing-page.web.app/events'
            target='_blank'
            rel='noreferrer'
            className='btn btn-sm btn-dashboard'
          >
            Visitar
          </a>
        </div>
      </div>
    </div>
  )
}
