import { Modal } from 'react-bootstrap';
import { presentationUrl } from '../utils';

export const CourseItemModal = ({ show, onHide, slideID }) => {
  return (
    <Modal
      className="google-slide-modal"
      show={show}
      size="xl"
      backdrop
      dialogClassName="google-slide-modal-content"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div style={{ width: '100%', height: '100%' }}>
          <iframe
            title="Evaluación de la escena"
            frameBorder={0}
            style={{ width: '100%', height: '100%' }}
            src={presentationUrl(slideID)}
            type="text/html"
            allowscriptaccess="always"
            allowFullScreen={true}
            allownetworking="all"
          ></iframe>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ padding: '5px 10px' }}>
        <button onClick={onHide} className="btn">
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  );
};
