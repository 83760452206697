import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { FinishedExam, Quiz } from "./components";
import { Error, Loader } from "../../../components";

import { useExam } from "../../../hooks";

import { clearExamResult, generateExamAdvance } from "../../../store/slices";

import { history, ROLES } from "../../../utils";

export const Exam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const { course, courseAdvance } = useSelector((state) => state);


  const { loading, error, idExam, quiz } = useExam({ user });

  const [actualQuestion, setActualQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [answerSelectedIndex, setAnswerSelectedIndex] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [dataResponses, setDataResponses] = useState([]);

  const handleAnswerSelect = (response) => {
    if (!isAnswerSelected) setIsAnswerSelected(true);

    const index = dataResponses.findIndex(
      (dataResponse) => dataResponse.questionId === response.questionId
    );

    if (index !== -1) {
      setDataResponses([
        ...dataResponses.slice(0, index),
        response,
        ...dataResponses.slice(index + 1),
      ]);
    } else {
      setDataResponses([...dataResponses, response]);
    }

    setAnswerSelectedIndex(response);
  };

  const handleAnswerSubmit = () => {
    if (isAnswerSelected) setIsAnswerSelected(false);

    setAnswerSelectedIndex(null);

    if (isAnswerCorrect) setScore(score + 1);

    if (actualQuestion === quiz.quizQuestions?.length - 1) {
      if (user.roleId === ROLES.user) {
        const newExamAdvance = {
          attempts: 1,
          sectionId: history.location.state.section_id,
          responses: dataResponses,
          courseId: course?.course?.id,
        };

        dispatch(generateExamAdvance(idExam, newExamAdvance));
      }

      setIsFinished(true);
    } else {
      setActualQuestion(actualQuestion + 1);
    }

    if (isAnswerCorrect) setIsAnswerCorrect(false);
  };

  useEffect(() => {
    if (!history.location.state?.slug || !history.location.state?.section_id) {
      return navigate("/");
    }

    dispatch(clearExamResult());

    if (!isFinished) return;
  }, [navigate, dispatch, isFinished]);

  if (loading) return <Loader delay={0} />;

  if (error) return <Error />;

  if (isFinished)
    return (
      <FinishedExam
        course={course?.course}
        score={courseAdvance?.examResult?.score}
        totalCorrectQuestions={courseAdvance?.examResult?.totalCorrectQuestions}
        totalQuestitons={courseAdvance?.examResult?.totalQuestions}
      />
    );

  return (
    <div className="exam-container w-100 h-100 d-flex justify-content-center align-items-center">
      <Quiz
        quiz={quiz}
        actualQuestion={actualQuestion}
        answerSelectedIndex={answerSelectedIndex}
        handleAnswerSelect={handleAnswerSelect}
        handleAnswerSubmit={handleAnswerSubmit}
        isAnswerSelected={isAnswerSelected}
      />
    </div>
  );
};
