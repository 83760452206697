import { useRef, useState } from "react";

export function usePagination({ elementsPerPage, totalElements }) {

   const contentRef = useRef();
   const [page, setPage] = useState(1);

   const pages = Math.ceil(totalElements / elementsPerPage);
   const lastIndex = page * elementsPerPage;
   const startIndex = lastIndex - elementsPerPage;

   const handleChangePage = (page) => {
      setPage(page);
      if (contentRef.current) {
         contentRef.current.closest('.main-container').scrollTo({
            top: 0,
            behavior: 'smooth'
         });
      }
   };

   const paginationRange = () => {
      if (pages <= 5 ) return Array.from(Array(pages).keys(), element => element + 1);

      if (page <= 3) return Array.from(Array(5).keys(), element => element + 1);

      if (page > 3 && page + 2 < pages) return Array.from(Array(5).keys(), element => page - 2 + element);

      if (page > 3 && page + 2 >= pages) return Array.from(Array(5).keys(), element => pages - 4 + element);

      return Array.from(Array(7).keys(), element => element + 1);
   };

   return { page, pages, startIndex, lastIndex, handleChangePage, paginationRange, contentRef };

}