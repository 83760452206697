import { useEffect, useState } from 'react';
import { setIsLoading, setMessage } from '../store/slices';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourses } from '../services';

export const useCourses = () => {
  const loading = useSelector((state) => state.loading);
  const [courses, setCourses] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(true));
    getAllCourses()
      .then((courses) => setCourses(courses))
      .catch((error) =>
        dispatch(
          setMessage({
            type: 'error',
            message: 'No fue posible obtener los recursos, por favor inténtelo nuevamente',
          })
        )
      )
      .finally(() => dispatch(setIsLoading(false)));
  }, [dispatch]);

  return { loading, courses };
};
