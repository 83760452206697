export function Certificate({ showAttemps = false, message, handleOnclick }) {
  return (
    <div
      className="progress-widget-container anim"
      style={{ "--delay": ".3s" }}
    >
      <ul className="list-unstyled">
        <li style={{ fontSize: "12px", textTransform: "uppercase" }}>
          <small>Certificado</small>
          <small className="info-tooltip" title="">
            <i className="fa fa-info-circle" />
          </small>
        </li>
        <li>
          <small style={{ fontSize: "10px", color: "#aaa" }}>
            <p style={{ marginBottom: "5px", lineHeight: "1.3rem" }}>
              {message.msg}
            </p>
          </small>
        </li>
        <li className="w-100 d-flex certificate-footer">
          <button
            onClick={handleOnclick}
            className="btn-outline-warning btn-md btn-block radius-md hasBeca"
          >
            {message.buttonLabel || <i className="fa-solid fa-lock"></i>}
          </button>

          {showAttemps && (
            <span style={{ fontSize: ".75rem" }}>{message.attempts}</span>
          )}

          <form id="payment-form" method="GET" />
        </li>
      </ul>
    </div>
  );
}
