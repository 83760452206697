export const VisibleIcon = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="currentColor"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlnsserif="http://www.serif.com/"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <rect
          id="Icons"
          x="-896"
          y="-256"
          width="1280"
          height="800"
          style={{ fill: "none" }}
        ></rect>
        <g id="Icons1" serifid="Icons">
          <g id="Strike"> </g> <g id="H1"> </g> <g id="H2"> </g> <g id="H3"> </g>
          <g id="list-ul"> </g> <g id="hamburger-1"> </g> <g id="hamburger-2"> </g>
          <g id="list-ol"> </g> <g id="list-task"> </g> <g id="trash"> </g>
          <g id="vertical-menu"> </g> <g id="horizontal-menu"> </g> <g id="sidebar-2"> </g>
          <g id="Pen"> </g>
          <g id="Pen1" serifid="Pen"></g>
          <g id="clock"> </g> <g id="external-link"> </g> <g id="hr"> </g> <g id="info"> </g>
          <g id="warning"> </g> <g id="plus-circle"> </g> <g id="minus-circle"> </g>
          <g id="vue"> </g> <g id="cog"> </g> <g id="logo"> </g> <g id="radio-check"> </g>
          <g id="eye-slash"> </g>
          <g id="eye">
            <path d="M32.513,13.926c10.574,0.15 19.141,9.894 23.487,18.074c0,0 -1.422,2.892 -2.856,4.895c-0.694,0.969 -1.424,1.913 -2.191,2.826c-0.547,0.65 -1.112,1.283 -1.698,1.898c-5.237,5.5 -12.758,9.603 -20.7,8.01c-8.823,-1.77 -15.732,-9.498 -20.058,-17.629c0,0 1.248,-2.964 2.69,-4.964c0.646,-0.897 1.324,-1.77 2.034,-2.617c0.544,-0.649 1.108,-1.282 1.691,-1.897c4.627,-4.876 10.564,-8.63 17.601,-8.596Zm-0.037,4c-5.89,-0.022 -10.788,3.267 -14.663,7.35c-0.527,0.555 -1.035,1.127 -1.527,1.713c-0.647,0.772 -1.265,1.569 -1.854,2.386c-0.544,0.755 -1.057,1.805 -1.451,2.59c3.773,6.468 9.286,12.323 16.361,13.742c6.563,1.317 12.688,-2.301 17.016,-6.846c0.529,-0.555 1.04,-1.128 1.534,-1.715c0.7,-0.833 1.366,-1.694 1.999,-2.579c0.557,-0.778 1.144,-1.767 1.588,-2.567c-3.943,-6.657 -10.651,-13.944 -19.003,-14.074Z"></path>
            <path d="M32.158,23.948c4.425,0 8.018,3.593 8.018,8.017c0,4.425 -3.593,8.017 -8.018,8.017c-4.424,0 -8.017,-3.592 -8.017,-8.017c0,-4.424 3.593,-8.017 8.017,-8.017Zm0,4.009c2.213,0 4.009,1.796 4.009,4.008c0,2.213 -1.796,4.009 -4.009,4.009c-2.212,0 -4.008,-1.796 -4.008,-4.009c0,-2.212 1.796,-4.008 4.008,-4.008Z"></path>
          </g>
          <g id="toggle-off"> </g> <g id="shredder"> </g>
          <g id="spinner--loading--dots-" serifid="spinner [loading, dots]"></g>
          <g id="react"> </g> <g id="check-selected"> </g> <g id="turn-off"> </g>
          <g id="code-block"> </g> <g id="user"> </g> <g id="coffee-bean"> </g>
          <g id="coffee-beans">
            <g id="coffee-bean1" serifid="coffee-bean"></g>
          </g>
          <g id="coffee-bean-filled"> </g>
          <g id="coffee-beans-filled">
            <g id="coffee-bean2" serifid="coffee-bean"></g>
          </g>
          <g id="clipboard"> </g> <g id="clipboard-paste"> </g> <g id="clipboard-copy"> </g>
          <g id="Layer1"> </g>
        </g>
      </g>
    </svg>
  );
};
