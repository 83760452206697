import { Certificado } from "../views/app/certificado/Certificado";
import { certificateMessages } from "../data/certificates.data";
import { Certificate } from "./Certificate";
import { useCertificate } from "../hooks/useCertificate";
import { ModalLayout } from "./ModalLayout";
import { UserForm } from "../views/app/certificado/UserForm";

export function Certificacion({
  user,
  course,
  percAvan = 0,
  percCal = 0,
  attemps = 0,
}) {
  const {
    show,
    showModal,
    handleOpenModal,
    handleCloseModal,
    handleShowCertificate,
    handleResetCourseAdvance,
    handlePayRetryCourse,
  } = useCertificate({ course });

  if (percAvan === 100 && percCal < 80 && attemps === 0) {
    return (
      <Certificate
        showAttemps
        message={certificateMessages.firstAttempt}
        handleOnclick={handleResetCourseAdvance}
      />
    );
  }

  if (percAvan === 100 && percCal < 80 && attemps >= 1 && attemps <= 2) {
    return (
      <Certificate
        showAttemps
        message={certificateMessages.failedOnce}
        handleOnclick={handleResetCourseAdvance}
      />
    );
  }

  if (percAvan === 100 && percCal < 80 && attemps >= 2) {
    return (
      <Certificate
        showAttemps
        message={certificateMessages.failedTwice}
        handleOnclick={handlePayRetryCourse}
      />
    );
  }

  if (
    percAvan === 100 &&
    percCal >= 80 &&
    (!user?.curp ||
      !user?.locationId ||
      !user?.market_stall ||
      !user?.specific_occupation)
  ) {
    return (
      <>
        <Certificate
          message={certificateMessages.infoRequired}
          handleOnclick={handleOpenModal}
        />
        <ModalLayout
          show={showModal}
          handleCloseModal={handleCloseModal}
          title="Formulario"
        >
          <UserForm />
        </ModalLayout>
      </>
    );
  }

  if (percAvan === 100 && percCal >= 80 && (!user?.curp || !user?.locationId)) {
    return (
      <>
        <Certificate
          message={certificateMessages.infoRequired}
          handleOnclick={handleOpenModal}
        />
        <ModalLayout
          show={showModal}
          handleCloseModal={handleCloseModal}
          title="Formulario"
        >
          <UserForm />
        </ModalLayout>
      </>
    );
  }

  if (percAvan === 100 && percCal >= 80) {
    return (
      <>
        <Certificate
          message={certificateMessages.approved}
          handleOnclick={handleShowCertificate}
        />
        {show && <Certificado handleShowCertificate={handleShowCertificate} />}
      </>
    );
  }

  return (
    <Certificate
      message={certificateMessages.default}
      handleOnclick={() => {}}
    />
  );
}
