import React, { useState } from 'react';
import { EmailForm, LoginForm, Message } from '../components';
import '../assets/css/dash.css';

export const LogIn = () => {
  const [recoverPassword, setRecoverPassword] = useState(false);

  const handleChangeToLogIn = () => setRecoverPassword(false);

  const handleChangeToRecoverPassword = () => setRecoverPassword(true);

  return (
    <div className='dashboard'>
      <div className='wrapper'>
        <Message />
        {recoverPassword ? (
          <EmailForm handleChangeToLogIn={handleChangeToLogIn} />
        ) : (
          <LoginForm handleChangeToRecoverPassword={handleChangeToRecoverPassword} />
        )}
      </div>
    </div>
  );
};
