import React from 'react';
import { CourseModule } from './CourseModule';
import { Accordion } from 'react-bootstrap';

export function CourseProgram({ course }) {
  return (
    <div className='col-lg-10'>
      <Accordion defaultActiveKey='0'>
        {course?.modules?.length > 0 &&
          course?.modules.map((courseModule, moduleIndex) => (
            <div key={courseModule.id} className='row program-item-container'>
              <CourseModule
                courseModules={course.modules}
                courseModule={courseModule}
                moduleIndex={moduleIndex}
              />
            </div>
          ))}
      </Accordion>
    </div>
  );
}
