import { Link, NavLink } from "react-router-dom";
import logo from "../assets/img/logo-transparent.png";
import { UserIcon } from "../components/Icons/UserIcon";
import { BooksIcon } from "../components/Icons/BooksIcon";
import { ServicesIcon } from "../components/Icons/ServicesIcon";
import { CartIcon } from "../components/Icons/CartIcon";
import { WishListIcon } from "../components/Icons/WishListIcon";
import { QuestionIcon } from "../components/Icons/QuestionIcon";
import { LogoutIcon } from "../components/Icons/LogoutIcon";
import { HomeIcon } from "../components/Icons/HomeIcon";
import { HelpIcon } from "../components/Icons/HelpIcon";
import { useAuth } from "../hooks";
import { ROLES } from "../utils";

export const Sidebar = ({ user }) => {
  const { logOut } = useAuth();

  return (
    <div className="sidebar">
      <span className="logo">PS</span>
      <Link to="/" className="logo-expand">
        <img src={logo} alt="" />
      </Link>

      <div className="side-wrapper">
        <div className="side-title">MENU</div>
        <div className="side-menu">
          <NavLink to="/" className="sidebar-link">
            <HomeIcon />
            Mis cursos
          </NavLink>
          {/* <NavLink to='/servicios' className='sidebar-link'>
            <svg width='100%' height='100%' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M6.99291 2.0293L1.35938 4.95215L3.73914 6.18716L6.99291 7.875L10.2451 6.18716L12.6249 4.95215L6.99291 2.0293Z' stroke='var(--icon-color)' strokeWidth='.75' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M10.1551 6.23291L6.99311 7.87484L3.82959 6.23291V7.98409L6.99311 9.62443L10.2453 7.93659V6.28041L10.1551 6.23291Z' stroke='var(--icon-color)' strokeWidth='.75' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M3.82959 9.42518V6.23315L6.64636 4.77173' stroke='var(--icon-color)' strokeWidth='.75' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            Cursos
          </NavLink> */}
          {user.roleId === ROLES.admin && (
            <NavLink to="/servicios" className="sidebar-link">
              <ServicesIcon />
              Prev-cursos
            </NavLink>
          )}
          <NavLink to="/courses" className="sidebar-link">
            <BooksIcon />
            Cursos
          </NavLink>
          {/* <NavLink to='/contenidos' className='sidebar-link'>
            <svg width='100%' height='100%' viewBox='0 0 20 20' fill='var(--icon-color)' xmlns='http://www.w3.org/2000/svg'>
              <path d='M15.9818 7.76913L7.12238 2.30804C6.48142 1.91344 5.70649 1.89703 5.04939 2.26415C4.39229 2.63121 4 3.29979 4 4.05246V14.9249C4 16.063 4.91699 16.9938 6.04405 17C6.04712 17 6.0502 17 6.05322 17C6.40542 17 6.77245 16.8896 7.11526 16.6804C7.39103 16.5121 7.47819 16.1522 7.30994 15.8765C7.14168 15.6007 6.78168 15.5135 6.506 15.6818C6.34566 15.7796 6.18907 15.8302 6.05034 15.8301C5.6251 15.8278 5.1698 15.4636 5.1698 14.9249V4.05249C5.1698 3.72961 5.33808 3.44288 5.61991 3.28541C5.90178 3.12794 6.23415 3.13497 6.50875 3.30404L15.3682 8.76514C15.6344 8.92908 15.7868 9.20245 15.7862 9.51517C15.7855 9.82785 15.632 10.1006 15.3643 10.264L8.95891 14.1859C8.6834 14.3546 8.5968 14.7147 8.76549 14.9902C8.93416 15.2657 9.29425 15.3523 9.56975 15.1836L15.9744 11.2621C16.5876 10.888 16.9546 10.2359 16.956 9.51751C16.9575 8.79921 16.5932 8.14553 15.9818 7.76913Z' fill='var(--icon-color)' />
            </svg>
            Contenidos
          </NavLink> */}
          <NavLink to="/cart" className="sidebar-link">
            <CartIcon />
            Carrito
          </NavLink>
          <NavLink to="/wishlist" className="sidebar-link">
            <WishListIcon />
            Deseados
          </NavLink>
          <NavLink to="/help" className="sidebar-link">
            <HelpIcon />
            Ayuda
          </NavLink>
          <NavLink to="/faq" className="sidebar-link">
            <QuestionIcon />
            Faq
          </NavLink>
        </div>
      </div>

      <div className="side-wrapper">
        <div className="side-title" />
        <div className="side-menu">
          {/* <Link to='#' className='sidebar-link'>
            <svg width='100%' height='100%' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M10.2333 5.395C10.1547 6.2461 9.83528 7.0572 9.31247 7.73339C8.78967 8.40958 8.08509 8.9229 7.2812 9.21327C6.47731 9.50364 5.60734 9.55906 4.7731 9.37305C3.93886 9.18703 3.17485 8.76727 2.57046 8.16289C1.96608 7.5585 1.54632 6.79449 1.3603 5.96025C1.17429 5.12601 1.22971 4.25604 1.52008 3.45215C1.81045 2.64825 2.32376 1.94368 2.99996 1.42088C3.67615 0.89807 4.48725 0.578653 5.33835 0.5C4.84006 1.17413 4.60027 2.00472 4.66262 2.84071C4.72496 3.67669 5.08528 4.46253 5.67805 5.0553C6.27082 5.64807 7.05666 6.00839 7.89264 6.07073C8.72862 6.13308 9.55922 5.89329 10.2333 5.395Z' stroke='var(--icon-color)' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            Dark Mode
          </Link> */}
          <NavLink to="/profile" className="sidebar-link">
            <UserIcon />
            Perfil
          </NavLink>
          <Link to="/login" onClick={logOut} className="sidebar-link">
            <LogoutIcon />
            Salir
          </Link>
        </div>
      </div>
    </div>
  );
};
