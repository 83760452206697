import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { history } from "../../../../utils";

export function FinishedExam({
  course,
  score,
  totalCorrectQuestions,
  totalQuestitons,
}) {
  const isLoadingExamResult = useSelector((state) => state.app.loadingExamResult);

  return (
    <div
      className="exam-container w-100 h-100 d-flex justify-content-center align-items-center anim"
      style={{ "--delay": ".5s" }}
    >
      <div className="course-card-container">
        <div className="content">
          <div className="top justify-content-center text-center">
          {isLoadingExamResult ? (
              <>
                <h3 className='text-orange'>Esperando resultado</h3>
                <Spinner animation='border' className='m-auto' />
              </>
            ) : (
              <>
                <h3 className='text-orange'>
                  Obtuviste {totalCorrectQuestions} de {totalQuestitons}{' '}
                </h3>
                <p
                  className='perc-value'
                  style={{
                    fontSize: '32px',
                    textTransform: 'uppercase',
                    margin: '0',
                  }}
                >
                  {score}%
                </p>
              </>
            )}
          </div>
          <div className="course-link w-100 p-2">
          {isLoadingExamResult ? (
              <Spinner size='sm' animation='border' className='m-auto' />
            ) : (
              <Link
                to={`/course/${course.id}`}
                onClick={() => {
                  history.replace({ ...history.location, state: null })
                }}
              >
                <span>Ir al curso</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
