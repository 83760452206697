import { useEffect, useRef, useState } from "react";
import { getUserCourseTimeSpent, sendCourseTimeSpentByUser } from "../services";

export const useCourseCounter = ({ courseId }) => {
  const [counter, setCounter] = useState({ minutes: 0, seconds: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const isMounted = useRef(true);
  const time = useRef({ minutes: 0, seconds: 0 });

  function calcTime(currentTime) {
    const { minutes, seconds } = currentTime;
    const newMinute = minutes + Math.floor((seconds + 1) / 60);
    const newSecond = (seconds + 1) % 60;

    return { minutes: newMinute, seconds: newSecond };
  }

  useEffect(() => {
    if (!courseId) return;

    async function fetchCourseTimeSpent() {
      if (!isMounted.current) return;
      try {
        setIsLoading(true);

        const res = await getUserCourseTimeSpent(courseId);
        setCounter(() => {
          if (res) {
            const newCounter = {
              minutes: res?.minutes || 0,
              seconds: 0,
            };
            time.current = { minutes: 0, seconds: 0 };
            return newCounter;
          } else {
            const newCounter = { minutes: 0, seconds: 0 };
            time.current = newCounter;
            return newCounter;
          }
        });
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCourseTimeSpent();

    return () => {
      isMounted.current = false;
    };
  }, [courseId]);

  useEffect(() => {
    function updateCounter() {
      setCounter((prevCounter) => {
        const newTime = calcTime(prevCounter);
        time.current = calcTime(time.current);
        return newTime;
      });
    }
    let intervalo = setInterval(updateCounter, 1000);

    function sendCounterToServer() {
      clearInterval(intervalo);
      if (time.current === null) return;
      sendCourseTimeSpentByUser(courseId, {...time.current, hours: 0 });
    }

    window.addEventListener("beforeunload", sendCounterToServer);

    return () => {
      clearInterval(intervalo);
      window.removeEventListener("beforeunload", sendCounterToServer);

      // Check this
      sendCounterToServer();
    };
  }, [courseId]);

  return { counter, isLoading, isError };
};
