import { currencyFormater, dateFormater, imageUrl } from "../utils";

import blogDefaultThum1 from "../assets/img/banner/banner5.jpg";

const dateFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

export const ApprovedPayment = ({ paymentStatus }) => {
  return (
    <div className="payment-status-summary cart">
      <header className="payment-status-header approved cart-header">
        <h4>Pago aprovado</h4>
        <p>Ya puedes iniciar a ver los siguientes cursos en tu panel de usuario.</p>
      </header>

      <ul className="cart-list">
        {paymentStatus.payment_data.additional_info.items.map((item) => (
          <li key={item.id} className="cart-item">
            <div className="item-content">
              <img src={imageUrl(item.thumb ? item.thumb : blogDefaultThum1)} alt={item.title} />
              <div className="item-description">
                <small className="item-title">{item.title}</small>
                <small className="item-price">
                  <del>{currencyFormater.format(item.price)}</del>
                </small>
                <span className="item-price item-discount-price">
                  {currencyFormater.format(item.price_discount)}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <div className="checkout-summary">
        <h4>Detalles</h4>
        <div className="summary-item">
          <h5>Estado</h5>
          <span>{paymentStatus.payment_data.status}</span>
        </div>
        <div className="summary-item">
          <h5>Fecha de pago</h5>
          <span>
            {dateFormater("es-MX", dateFormatOptions).format(
              paymentStatus.payment_data.additional_info.date_approved
            )}
          </span>
        </div>
        <div className="summary-item">
          <h5>Número de operación</h5>
          <span>{paymentStatus.payment_data.id}</span>
        </div>
        <div className="summary-item">
          <h5>Total</h5>
          <span>{currencyFormater.format(paymentStatus.payment_data.transaction_amount)}</span>
        </div>
      </div>
    </div>
  );
};
