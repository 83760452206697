import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  clearCourse,
  clearCourseAdvance,
  clearUser,
  clearUserCourses,
  setMessage,
  sigIn,
} from "../store/slices";
import { removeStateApp, removeUserFromLocalStorage } from "../utils";

export function useAuth() {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const logIn = (credentials) => dispatch(sigIn(credentials));

  const logOut = () => {
    removeUserFromLocalStorage();
    removeStateApp();
    dispatch(clearUser());
    dispatch(clearUserCourses());
    dispatch(clearCourseAdvance());
    dispatch(clearCourse());
    dispatch(clearCart());
    dispatch(setMessage({ type: "logout" }));
  };

  return { user, logIn, logOut };
}
