import React from "react";
import { Link } from "react-router-dom";

export function CourseCard({ item, imageUrl }) {
  return (
    <div className="cours-bx anim" style={{ "--delay": ".5s" }}>
      <div className="action-box">
        <img src={imageUrl(item?.thumb)} alt="" />
        <Link to={`/course/details/${item?.id}`} className="btn">
          Leer Más
        </Link>
      </div>
      <div className="info-bx">
        <span>{item?.category?.name}</span>
        <h6>
          <Link to={`/course/details/${item?.id}`}>{item?.title}</Link>
        </h6>
      </div>
      <div className="cours-more-info">
        <div className="review">
          {/* <span>{item.Review} Reseña</span> */}
          {/* <ul className='cours-star'>
            <li className='active'>
              <i className='fa fa-star' />
            </li>
            <li className='active'>
              <i className='fa fa-star' />
            </li>
            <li className='active'>
              <i className='fa fa-star' />
            </li>
            <li>
              <i className='fa fa-star' />
            </li>
            <li>
              <i className='fa fa-star' />
            </li>
          </ul> */}
        </div>

        <div className="price">
          {item.discount > 0 && <del>${item.price}</del>}
          <h5>${item.discount > 0 ? item.price_discount : item.price}</h5>
        </div>
      </div>
    </div>
  );
}
