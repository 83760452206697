import React from "react";
import { AnswerOption } from "./AnswerOption";

export function Quiz({
  quiz,
  actualQuestion,
  answerSelectedIndex,
  handleAnswerSelect,
  handleAnswerSubmit,
  isAnswerSelected,
}) {
  return (
    <div
      className="course-card-container w-100 anim"
      style={{ "--delay": ".7s" }}
    >
      <div className="content w-100">
        <div className="top w-100" style={{ overflowY: "auto" }}>
          <div className="d-flex flex-column bd-highlight justify-content-center">
            <div>
              <span> Pregunta {actualQuestion + 1} de</span>{" "}
              {quiz.quizQuestions?.length}
            </div>
            <p className="fs22">
              {quiz.quizQuestions?.[actualQuestion]?.question}
            </p>

            <div className="flex-column bd-highlight mb-2 justify-content-center">
              <div className="card-body content gap-3">
                {quiz.quizQuestions?.[actualQuestion]?.questionOptions?.map(
                  (answerOption, answerOptionIndex) => (
                    <AnswerOption
                      key={answerOption.id}
                      question={quiz.quizQuestions?.[actualQuestion]}
                      answerOption={answerOption}
                      answerOptionIndex={answerOptionIndex}
                      answerSelectedIndex={answerSelectedIndex}
                      handleAnswerSelect={handleAnswerSelect}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="course-link py-2 px-4">
          <button
            className="btn radius-xl w-100 my-1 font-weight-bold"
            onClick={handleAnswerSubmit}
            disabled={!isAnswerSelected}
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
}
