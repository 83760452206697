import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setUser } from '../../../../store/slices';
import {
  getOccupations,
  getSpecificOccupations,
  getStates,
  getStateTowns,
  updateUser,
} from '../../../../services';
import { setUserToLocalStorage } from '../../../../utils';

const initialValues = {
  curp: '',
  stateId: '',
  townId: '',
  market_stall: '',
  specific_occupation: '',
};

export function useUserForm() {
  const userFormSchema = yup.object().shape({
    curp: yup.string().required('Este campo es requerido.'),
    stateId: yup.string().required('Este campo es requerido.'),
    townId: yup.string().required('Este campo es requerido.'),
    market_stall: yup.string().required('Este campo es requerido.'),
    specific_occupation: yup.string().required('Este campo es requerido.'),
  });

  const [stateId, setStateId] = useState(null);
  const [occupationKey, setOccupationKey] = useState(null);
  const [states, setStates] = useState([]);
  const [towns, setTowns] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [specificOccupations, setSpecificOccupations] = useState([]);

  const dispatch = useDispatch();

  const userFormRef = (formikData) => {
    if (!formikData) return;

    const { values } = formikData;

    if (values.stateId) setStateId(values.stateId);
    if (values.market_stall) setOccupationKey(values.market_stall);
  };

  const handleSubmit = (data, { resetForm }) => {
    const userOccupation =
      occupations.find((occupation) => occupation.clave === Number(data.market_stall))
        .name_ocupation || '';
    const userSpecificOccupation =
      specificOccupations.find(
        (specificOccupation) => specificOccupation.clave === Number(data.specific_occupation)
      ).name_ocupation || '';

    const updatedUser = {
      ...data,
      market_stall: userOccupation,
      specific_occupation: userSpecificOccupation,
    };

    const res = updateUser(updatedUser);

    toast.promise(
      res,
      {
        loading: 'Espera...',
        success: 'Informacón actualizada correctamente',
        error: 'Ha ocurrido un error, inténtalo nuevamente',
      },
      {
        position: 'top-right',
        duration: 4000,
      }
    );

    res
      .then((userAuth) => {
        dispatch(setUser(userAuth.user));

        setUserToLocalStorage(userAuth);
      })
      .catch((err) => {
        if (err.response.status === 401) return;
      })
      .finally(() => {
        resetForm();
      });
  };

  const handleCancell = (resetForm) => resetForm();

  useEffect(() => {
    let isMounted = true;

    Promise.all([getStates(), getOccupations()])
      .then((res) => {
        const [states, occupations] = res;

        if (isMounted) {
          setStates(states);
          setOccupations(occupations);
        }
      })

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (!stateId) return;

    getStateTowns(stateId)
      .then((towns) => {
        if (isMounted) setTowns(towns);
      })

    return () => {
      isMounted = false;
    };
  }, [stateId]);

  useEffect(() => {
    let isMounted = true;

    if (!occupationKey) return;

    getSpecificOccupations(occupationKey)
      .then((specificOccupations) => {
        if (isMounted) setSpecificOccupations(specificOccupations);
      })

    return () => {
      isMounted = false;
    };
  }, [occupationKey]);

  return {
    userFormRef,
    initialValues,
    userFormSchema,
    states,
    towns,
    occupations,
    specificOccupations,
    handleSubmit,
    handleCancell,
  };
}


/* import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setUser } from '../../../../store/slices';
import { getStates, getStateTowns, updateUser } from '../../../../services';
import { setUserToLocalStorage } from '../../../../utils';

const initialValues = {
  curp: '',
  stateId: '',
  townId: '',
};

export function useUserForm() {
  const userFormSchema = yup.object().shape({
    curp: yup.string().required('Este campo es requerido.'),
    stateId: yup.string().required('Este campo es requerido.'),
    townId: yup.string().required('Este campo es requerido.'),
  });

  const [stateId, setStateId] = useState(null);
  const [states, setStates] = useState([]);
  const [towns, setTowns] = useState([]);

  const dispatch = useDispatch();

  const userFormRef = (formikData) => {
    if (!formikData) return;

    const { values } = formikData;

    if (values.stateId) setStateId(values.stateId);
  };

  const handleSubmit = (data, { resetForm }) => {
    const res = updateUser(data);

    toast.promise(
      res,
      {
        loading: 'Espera...',
        success: 'Informacón actualizada correctamente',
        error: 'Ha ocurrido un error, inténtalo nuevamente',
      },
      {
        position: 'top-right',
        duration: 4000,
      }
    );

    res
      .then((userAuth) => {
        dispatch(setUser(userAuth.user));

        setUserToLocalStorage(userAuth);
      })
      .catch((err) => {
        console.error(err.message);
        if (err.response.status === 401) return;
      })
      .finally(() => {
        resetForm();
      });
  };

  const handleCancell = (resetForm) => resetForm();

  useEffect(() => {
    let isMounted = true;

    getStates()
      .then((states) => {
        if (isMounted) setStates(states);
      })
      .catch((err) => console.error(err.message));

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (!stateId) return;

    getStateTowns(stateId)
      .then((towns) => {
        if (isMounted) setTowns(towns);
      })
      .catch((err) => console.error(err.message));

    return () => {
      isMounted = false;
    };
  }, [stateId]);

  return { userFormRef, initialValues, userFormSchema, states, towns, handleSubmit, handleCancell };
} */
