import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useCourseModule({ courseModules, courseModule, moduleIndex }) {
  const courseAdvance = useSelector((state) => state.courseAdvance);
  const previousModule = courseModules[moduleIndex - 1];

  const moduleQuizAdvance = useMemo(
    () => courseAdvance?.examsAdvance?.find((exam) => exam.section.id === courseModule.id),
    [courseAdvance.examsAdvance, courseModule.id]
  );

  const previousModuleQuizAdvance = useMemo(
    () => courseAdvance?.examsAdvance?.find((exam) => exam.section.id === previousModule?.id),
    [courseAdvance.examsAdvance, previousModule?.id]
  );

  return { moduleQuizAdvance, previousModuleQuizAdvance };
}
