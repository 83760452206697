import { useState } from "react";
import toast from "react-hot-toast";
import { TICKET_AREAS } from "../../utils/ticket-areas.util";
import { createTicket } from "../../services";

const initialValues = {
  area: "",
  description: "",
};

export const Support = () => {
  const [itemIndex, setItemIndex] = useState(0);
  const [values, setValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const handleNextItem = () => {
    setItemIndex((prevItemIndex) => {
      if (prevItemIndex === 1) return prevItemIndex;
      return prevItemIndex + 1;
    });
  };

  const handlePrevItem = () => {
    setItemIndex((prevItemIndex) => {
      if (prevItemIndex === 0) return prevItemIndex;
      return prevItemIndex - 1;
    });
  };

  const handleChange = (e) => {
    if (e.target.type === "radio") {
      setItemIndex((prevItemIndex) => {
        if (prevItemIndex === 1) return prevItemIndex;
        return prevItemIndex + 1;
      });
    }
    setValues((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await createTicket(values);
      toast.success("¡Hemos recibido tu petición!");
      setValues(initialValues);
    } catch (error) {
      toast.error("¡Ha ocurrido un error, inténtalo de nuevo más tarde!");
    } finally {
      setIsLoading(false);
    }
  };

  const showSubmitButton = () => {
    if (itemIndex !== 1) return false;
    for (const key in values) {
      if (values[key] === "") return false;
    }
    return true;
  };

  return (
    <section className="support">
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        Centro de ayuda
      </div>

      <div className="support-wrapper anim" style={{ "--delay": ".5s" }}>
        <div className="slider-wrapper">
          <div className="slider-item" style={{ "--move": `-${itemIndex * 100}%` }}>
            <div className="slider-item-content">
              <h2>
                1 <i className="fas fa-long-arrow-alt-right"></i> ¿En qué necesitas ayuda?
              </h2>
              <p>Seleccione una de las opciones.</p>
              <div>
                <ol>
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="area"
                        value={TICKET_AREAS.BILLING}
                        onChange={handleChange}
                        checked={values.area === TICKET_AREAS.BILLING}
                      />{" "}
                      {TICKET_AREAS.BILLING}
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="area"
                        value={TICKET_AREAS.DOUBTS}
                        onChange={handleChange}
                        checked={values.area === TICKET_AREAS.DOUBTS}
                      />{" "}
                      {TICKET_AREAS.DOUBTS}
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="area"
                        value={TICKET_AREAS.TECHNICAL_SERVICE}
                        onChange={handleChange}
                        checked={values.area === TICKET_AREAS.TECHNICAL_SERVICE}
                      />{" "}
                      {TICKET_AREAS.TECHNICAL_SERVICE}
                    </label>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="slider-item" style={{ "--move": `-${itemIndex * 100}%` }}>
            <div className="slider-item-content">
              <h2>
                2 <i className="fas fa-long-arrow-alt-right"></i> Redacta brevemente cómo podemos
                ayudar.
              </h2>
              <div>
                <textarea
                  rows="5"
                  name="description"
                  placeholder="Escribe aquí tu respuesta..."
                  value={values.description}
                  onChange={handleChange}
                  maxLength={500}
                />
                <small>{values.description.length}/500</small>
              </div>
            </div>
          </div>
        </div>

        <div className="slider-actions">
          <button
            disabled={isLoading}
            onClick={handleSubmit}
            className={`send-button ${!showSubmitButton() ? "hide" : ""}`}
          >
            Enviar
          </button>
          <div>
            <button disabled={itemIndex === 0} onClick={handlePrevItem}>
              <i className="fas fa-chevron-up"></i>
            </button>
            <button disabled={itemIndex === 1} onClick={handleNextItem}>
              <i className="fas fa-chevron-down"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
