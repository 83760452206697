import React from "react"
import moment from "moment"
import "moment/locale/es"
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer"
import certPS1 from "../../../assets/img/certPS1.jpg"
import arial from "../../../assets/fonts/arial.ttf"
import arialbd from "../../../assets/fonts/arialbd.ttf"
import arialbi from "../../../assets/fonts/arialbi.ttf"

Font.register({
  family: "Arial",
  fonts: [
    { src: arial },
    { src: arialbd, fontWeight: "bold" },
    { src: arialbi, fontWeight: "bold", fontStyle: "italic" },
  ],
})

// Create styles
const styles = StyleSheet.create({
  body: {
    margin: 0,
    paddingBottom: -10,
  },
  imagen: {
    backgroundColor: "transparent",
    position: "relative",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
  },
  signature: {
    width: "230px",
    height: "125px",
    objectFit: "contain",
    position: "absolute",
    transform: "translateX(-50%)",
  },
  text: {
    fontFamily: "Arial",
    fontSize: 18,
    position: "absolute",
    textAlign: "center",
    width: "100%",
  },
})

// Create Document Component9
export const CertificatePageTwo = ({ user, course, courseAdvance, register }) => {
  moment.locale("es")
  const date = moment(
    new Date(
      courseAdvance.examsAdvance?.[
        courseAdvance.examsAdvance.length - 1
      ].section?.quiz?.quizAdvances?.[0].created_at
    )
  )
    .format("LLL")
    .split(/\d+:\d+/)[0]

  return (
    <Page
      key={`${user.CURP}-${course?.course?.id}`}
      size="LETTER"
      orientation="landscape"
      style={styles.body}
    >
      <View wrap={false}>
        <Image src={certPS1} alt="Proceso" style={styles.imagen} />

        <Text
          style={[
            styles.text,
            {
              marginTop: "160",
              fontSize: "22",
            },
          ]}
        >
          Se otorga el presente certificado a:
        </Text>
        <Text
          style={[
            styles.text,
            {
              marginTop: "210",
              fontSize: "22",
              fontWeight: "bold",
              fontStyle: "italic",
            },
          ]}
        >
          {user.name} {user.lastname}
        </Text>
        <Text
          style={[
            styles.text,
            { marginTop: "235", fontSize: "18", fontWeight: "bold", fontStyle: "italic" },
          ]}
        >
          {user.curp}
        </Text>

        <Image
          source={{ header: { "Access-Control-Allow-Origin": "*" } }}
          src={register?.instructor?.signatureInstructors?.[0]?.signature}
          alt="Firma Instructor"
          style={[styles.signature, { top: "408", left: "41.5%" }]}
        />

        <Text
          style={[
            styles.text,
            {
              lineHeight: 1.5,
              width: "85%",
              marginTop: "265",
              left: "7.5%",
            },
          ]}
        >
          Por haber acreditado el curso de "{course?.course?.title}".{"\n"}
          El día {date}, con una duración de {course?.course?.duration_course} horas,{"\n"}
          impartido en las instalaciones de {user?.company?.business_name ||
            "Proceso Seguro S.C."}{" "}
          con dirección en{"\n"}
          {user?.address
            ? user?.address
            : "Av. Anillo Vial II Fray Junípero Serra Km 4+475, La Vista Residencial, C.P. 76146, Santiago de Querétaro, Querétaro"}
        </Text>

        <Text style={[styles.text, { marginTop: "540", fontSize: "14" }]}>
          Lic. {register?.instructor?.name} {register?.instructor?.lastname}
        </Text>
        <Text style={[styles.text, { marginTop: "558", fontSize: "12" }]}>
          {register?.register}
        </Text>
        <Text
          style={[
            styles.text,
            {
              marginTop: "570",
              fontSize: "12",
            },
          ]}
        >
          {`N° Folio: PS-${user?.id || ""}${user.curp}${course?.course?.id || ""}`}
        </Text>
        <Text
          style={[
            styles.text,
            {
              marginTop: "570",
              fontSize: "12",
              marginLeft: "225",
            },
          ]}
        >
          Vigencia: 1 año
        </Text>
      </View>
    </Page>
  )
}
