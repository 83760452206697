import { Spinner } from "react-bootstrap";
import { useCourseCounter } from "../hooks";

export const CourseCounter = ({ courseId }) => {
  const { counter, isLoading, isError } = useCourseCounter({ courseId });
  // console.log(counter);

  return (
    <div className="progress-widget-container anim" style={{ "--delay": ".2s" }}>
      <div>
        <ul className="list-unstyled">
          <li style={{ fontSize: "12px", textTransform: "uppercase" }}>
            <small>Tiempo invertido</small>
          </li>
          <li>
            {isLoading && !counter && (
              <div className="d-flex flex-column justify-content-between align-items-center mt-4">
                <Spinner animation="border" variant="warning" className="m-auto" />
              </div>
            )}
            {!isLoading && !isError && counter && (
              <b className="perc-value">
                {Math.floor(counter.minutes / 60)}H:{counter.minutes % 60}m
              </b>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};
