import React from 'react';
import moment from 'moment';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import formPS from '../../../assets/img/formPS.jpg';
import { CATEGORIES } from '../../../utils/constants';

// Create styles
const styles = StyleSheet.create({
  body: {
    margin: 0,
    paddingBottom: -10,
  },
  imagen: {
    position: 'relative',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
  },
  companyLogo: {
    width: '130px',
    maxHeight: '100px',
    objectFit: 'contain',
    position: 'absolute',
    bottom: '86.1%',
    left: '35',
  },
  signature: {
    backgroundColor: 'transparent',
    height: '80px',
    objectFit: 'contain',
    position: 'absolute',
    top: '514.5',
    transform: 'translateX(-50%)',
  },
  text: {
    position: 'absolute',
    width: '70%',
    marginLeft: '45',
    fontSize: '9',
  },
});

// Create Document Component
export default function CertificatePageOne({ user, course, courseAdvance, register }) {
  const startDate = moment(new Date(courseAdvance?.classesAdvance?.[0].createdAt)).format(
    'YYYY-MM-DD'
  );

  const endDate = moment(
    new Date(
      courseAdvance.examsAdvance?.[
        courseAdvance.examsAdvance.length - 1
      ].section?.quiz?.quizAdvances?.[0].created_at
    )
  ).format('YYYY-MM-DD');

  return (
    <Page key={`${user?.curp}-${course?.course?.id}`} size='LETTER' style={styles.body}>
      <View wrap={false}>
        <Image src={formPS} alt='Proceso' style={styles.imagen} />
        {user?.company?.company_logo && (
          <Image
            source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
            src={user?.company?.company_logo}
            alt='Logo Empresa'
            style={styles.companyLogo}
          />
        )}
        <Text style={[styles.text, { marginTop: '156' }]}>
          {user?.name} {user?.lastname}
        </Text>
        <Text style={[styles.text, { marginTop: '186' }]}>{user?.curp}</Text>
        <Text style={[styles.text, { marginTop: '186', left: '42%' }]}>
          {user?.specific_occupation || 'N.A'}
        </Text>
        <Text style={[styles.text, { marginTop: '220' }]}>{user?.market_stall || 'N.A'}</Text>
        <Text style={[styles.text, { marginTop: '272' }]}>
          {user?.company?.business_name || 'N.A'}
        </Text>
        <Text style={[styles.text, { marginTop: '305' }]}>
          {user?.company?.federal_taxpayer_registration || 'N.A'}
        </Text>
        <Text style={[styles.text, { width: '85%', marginTop: '350' }]}>{course?.course?.title}</Text>
        <Text style={[styles.text, { marginTop: '389' }]}>{course?.course?.duration_course} horas</Text>
        <Text style={[styles.text, { marginTop: '389', textAlign: 'right', paddingRight: '110' }]}>
          {startDate}
        </Text>
        <Text
          style={{
            position: 'absolute',
            width: '82%',
            fontSize: '9',
            marginTop: '389',
            textAlign: 'right',
            marginLeft: '3',
          }}
        >
          {endDate}
        </Text>
        <Text style={[styles.text, { marginTop: '454' }]}>
        {Number(course?.course?.categoryId) === CATEGORIES.proteccionCivil
          ? 'PPS130829DF8-0013'
          : register?.register}
        </Text>
        {Number(course?.course?.categoryId) === CATEGORIES.proteccionCivil
          ? (
            <Image
              source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
              src='https://leptolum.sirv.com/Firmas/firma_don_berrueta_final.jpg'
              alt='Firma Instructor'
              style={[styles.signature, { width: '155px', left: '15.25%' }]}
            />
          )
          : (
            <Image
              source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
              src={register?.instructor?.signatureInstructors?.[0]?.signature}
              alt='Firma Instructor'
              style={[styles.signature, { width: '160px', left: '15.25%' }]}
            />
          )}
        <Text
          style={[
            styles.text,
            {
              textAlign: 'center',
              width: '155px',
              marginTop: '584',
            },
          ]}
        >
          {Number(course?.course?.categoryId) === CATEGORIES.proteccionCivil
            ? 'Francisco Javier Berrueta González'
            : `${register?.instructor?.name} ${register?.instructor?.lastname}`}
        </Text>
        {user?.company?.representLegal?.signature && (
          <Image
            source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
            src={user?.company?.representLegal?.signature}
            alt='Firma Representante Legal'
            style={[styles.signature, { width: '180px', left: '42.5%' }]}
          />
        )}
        <Text
          style={[
            styles.text,
            {
              textAlign: 'center',
              width: '155px',
              left: '28.2%',
              marginTop: '584',
            },
          ]}
        >
          {user?.company?.representLegal?.name || 'N.A'}
        </Text>
        {user?.company?.representWorker?.signature && (
          <Image
            source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
            src={user?.company?.representWorker?.signature}
            alt='Firma Representante Trabajadores'
            style={[styles.signature, { width: '171px', left: '73.5%' }]}
          />
        )}
        <Text
          style={[
            styles.text,
            {
              textAlign: 'center',
              width: '155px',
              left: '58.6%',
              marginTop: '584',
            },
          ]}
        >
          {user?.company?.representWorker?.name || 'N.A'}
        </Text>
      </View>
    </Page>
  );
}
