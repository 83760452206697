import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import routes from "./routes";
import "./App.css";

// Plugins Stylesheet
import "bootstrap/dist/css/bootstrap.min.css";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import "react-modal-video/css/modal-video.min.css";

// StyleSheet
import "./assets/css/typography.css";
import "./assets/css/shortcodes/shortcodes.css";
import "./assets/css/style.css";
import "./assets/css/color/color.css";

// Fonts
import "./assets/vendors/fontawesome/css/font-awesome.min.css";
// import './vendors/flaticon/flaticon.css'
// import './vendors/line-awesome/css/line-awesome.min.css'
import "./assets/vendors/themify/themify-icons.css";

function App() {
  const routing = useRoutes(routes());

  return (
    <div className="page-wraper">
      <Provider store={store}>{routing}</Provider>
    </div>
  );
}

export default App;
