import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allUserCourses } from "../../store/slices/userCourses.slice";
import { getInitialUserCart } from "../../store/slices/cart.slice";
import { Header, Message } from "../../components";
import { Sidebar } from "../../layouts/Sidebar";
import { setUser } from "../../store/slices";
import { Outlet } from "react-router-dom";
import "../../assets/css/dash.css";

export const App = () => {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    dispatch(setUser(user));
    dispatch(allUserCourses());
    dispatch(getInitialUserCart());
  }, [dispatch, user]);

  return (
    <div className="dashboard">
      <Sidebar user={user} />
      <div className="wrapper">
        <Header user={user} />
        <div className="main-container">
          <Message />
          <Outlet />
        </div>
      </div>
    </div>
  );
};
