import React from "react";

import {
  App,
  Course,
  Dashboard,
  Error404,
  Exam,
  Service,
  LogIn,
  Courses,
  Profile,
  CourseDetails,
  Faqs,
  Cart,
  Wishlist,
  PaymentStatus,
  Support,
  Recover,
} from "../views";

import { PrivateRoutes } from "../layouts/PrivateRoutes";

const routes = () => [
  {
    path: "/",
    element: (
      <PrivateRoutes>
        <App />
      </PrivateRoutes>
    ),
    children: [
      {
        index: true,
        element: (
          <PrivateRoutes>
            <Dashboard />
          </PrivateRoutes>
        ),
      },
      {
        path: "/servicios",
        element: (
          <PrivateRoutes role="admin">
            <Service />
          </PrivateRoutes>
        ),
      },
      {
        path: "/course/:idCourse",
        element: (
          <PrivateRoutes>
            <Course />
          </PrivateRoutes>
        ),
      },
      /* {
        path: '/contenidos',
        element: <Contains />,
      }, */
      {
        path: "/examen/:idExam",
        element: (
          <PrivateRoutes>
            <Exam />
          </PrivateRoutes>
        ),
      },
      {
        path: "/courses",
        element: <Courses />,
      },
      {
        path: "/course/details/:idCourse",
        element: (
          <PrivateRoutes>
            <CourseDetails />
          </PrivateRoutes>
        ),
      },
      {
        path: "/cart",
        element: <Cart />,
      },

      {
        path: "/wishlist",
        element: <Wishlist />,
      },
      {
        path: "/payment/cart/status",
        element: <PaymentStatus />,
      },
      {
        path: "/help",
        element: <Support />,
      },
      {
        path: "/faq",
        element: <Faqs />,
      },
      {
        path: "/profile",
        element: (
          <PrivateRoutes>
            <Profile />
          </PrivateRoutes>
        ),
      },
      {
        path: "*",
        element: <Error404 />,
      },
      // {
      //   path: '/cursos',
      //   element: JSON.stringify(user) !== '{}' ? <></> : <Navigate to="/login" />,
      //   children: [
      //     { index: true, element: <Navigate to="/admin/dashboard" /> },
      //     // { path: '/admin/dashboard', element: <Dashboard /> },
      //     // { path: '/admin/pending', element: <Pending /> },
      //   ],
      // },
      // { path: '/login', element: JSON.stringify(user) !== '{}' ? <Navigate to="/admin/dashboard" /> : <Login /> },
    ],
  },
  {
    path: "/login",
    element: <LogIn />,
  },
  {
    path: "/password-recovery/:token",
    element: <Recover />,
  },
];

export default routes;
