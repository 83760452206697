import { useSelector } from "react-redux";
import { Courses, Events } from "./components";
import { ROLES } from "../../../utils";

export const Dashboard = () => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        Inicio
      </div>

      <div className="courses-content items-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="dashboard-banners-container">
              <div className="col-sm-12 my-4 pl-0">
                <h6 className="welcome-label text-white ch-mt-2 anim" style={{ "--delay": ".5s" }}>
                  <span>¡Te damos la bienvenida, {user.name}!</span>
                </h6>
              </div>

              <div className="row">
                <Events />
              </div>
            </div>
          </div>

          {user.roleId === ROLES.user && (
            <div className="col-sm-12 my-3">
              <div className="tab-list-container mt-4 anim" style={{ "--delay": ".8s" }}>
                <div className="list d-flex">
                  <div className="tab active">
                    <span className="label active">Mis Cursos</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Courses />
        </div>
      </div>
    </>
  );
};
