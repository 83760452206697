import axios from "../services/axios.service";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { resetCourseAdvance } from "../store/slices/courseAdvance.slice";
import { useScript } from "./useScript";

export function useCertificate({ course }) {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { MercadoPago } = useScript(process.env.REACT_APP_MERCADO_PAGO_SDK_URL, "MercadoPago");

  const dispatch = useDispatch();

  const handleShowCertificate = () => {
    setShow(!show);
  };

  const handleOpenModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const handleResetCourseAdvance = () => dispatch(resetCourseAdvance(course.id));

  const handlePayRetryCourse = async (e) => {
    e.target.setAttribute("disabled", true);

    const mercadopago = new MercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, {
      locale: "es-MX",
    });

    const preference = await axios.post("/payment", { courseId: course.id, url: "dashboard" });

    mercadopago.checkout({
      preference: {
        id: preference.data.id,
      },
      autoOpen: true,
    });

    e.target.removeAttribute("disabled");
  };

  return {
    show,
    showModal,
    handleOpenModal,
    handleCloseModal,
    handleShowCertificate,
    handleResetCourseAdvance,
    handlePayRetryCourse,
  };
}
