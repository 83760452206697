import React from "react";
import { ModuleClass } from "./ModuleClass";
import { Accordion } from "react-bootstrap";
import { ModuleExam } from "./ModuleExam";
import { useCourseModule } from "../hooks/useCourseModule";

export function CourseModule({ courseModules, courseModule, moduleIndex }) {
  const { moduleQuizAdvance, previousModuleQuizAdvance } = useCourseModule({
    courseModules,
    courseModule,
    moduleIndex,
  });

  return (
    <div className="col-sm-12 program-item-content flag-challenges">
      <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
        <div className="progress-bar-perc" />
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Accordion.Item eventKey={courseModule.id}>
            <div
              className="program-item-headline anim"
              style={{ "--delay": ".3s" }}
            >
              <div className="row">
                <div className="col-sm-7 program-item-title-container program-item-title">
                  <div className="program-item-progress">
                    <i className="fa fa-check-circle" />
                  </div>
                  <div className="program-item-label">
                    <Accordion.Header className="stage-title">
                      {courseModule?.title}
                    </Accordion.Header>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Item>
        </div>
      </div>
      <div className="row challenges-container">
        <div className="col-sm-11 offset-sm-1 ">
          <Accordion.Collapse eventKey={courseModule.id}>
            <div className="row challenge-list">
              {courseModule?.classes?.map((moduleClass, moduleClassIndex) => (
                <div
                  key={moduleClass.id}
                  className="col-sm-12 challenge-item-container"
                >
                  <ModuleClass
                    previousModuleQuizAdvance={previousModuleQuizAdvance}
                    prevCourseModule={courseModules[moduleIndex - 1]}
                    courseModule={courseModule}
                    moduleClass={moduleClass}
                    moduleIndex={moduleIndex}
                    moduleClassIndex={moduleClassIndex}
                  />
                </div>
              ))}
              {courseModule.quizId && (
                <ModuleExam
                  moduleQuizAdvance={moduleQuizAdvance}
                  courseModule={courseModule}
                />
              )}
            </div>
          </Accordion.Collapse>
        </div>
      </div>
    </div>
  );
}
