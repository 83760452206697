import React from "react";

export const AnswerOption = ({
  answerOption,
  question,
  answerOptionIndex,
  answerSelectedIndex,
  handleAnswerSelect,
}) => {
  return (
    <button
      className={`btn radius-xl my-1 font-weight-bold btn-answer-option
      ${answerOption?.id === answerSelectedIndex?.questionOptionId ? "answer-selected" : ""}`}
      onClick={() =>
        handleAnswerSelect({
          questionId: question.id,
          questionOptionId: answerOption.id,
        })
      }
    >
      {answerOption.answer}
    </button>
  );
};
