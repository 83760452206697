export const certificateMessages = {
  infoRequired: {
    msg: "Antes de obtener tu certificado, necesitamos que nos brindes información sobre ti.",
    buttonLabel: "Ir al formulario",
  },
  firstAttempt: {
    msg: "Podras obtener tu Certificado luego de alcanzar el 100% del Avance y 80% de Calificación.",
    buttonLabel: "Reintentar",
    attempts: "Intentos restantes 2 / 2",
  },
  failedOnce: {
    msg: "Podras obtener tu Certificado luego de alcanzar el 100% del Avance y 80% de Calificación.",
    buttonLabel: "Reintentar",
    attempts: "Intentos restantes 1 / 2",
  },
  failedTwice: {
    msg: "Es necesario hacer un nuevo pago para desbloquear nuevamente el curso.",
    buttonLabel: "Pagar",
    attempts: "Intentos restantes 0 / 2",
  },
  approved: {
    msg: "Felicidades por haber completado el curso, acontinuación podrás obtener tus certificados.",
    buttonLabel: "Ver Certificado",
  },
  default: {
    msg: "Podras obtener tu Certificado luego de alcanzar el 100% del Avance y 80 de Calificación.",
    buttonLabel: "",
  },
};
