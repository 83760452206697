import axios from "./axios.service";
import { isObjectEmpty } from "../utils/isObjectEmpty.util";

export const getStatusPaymentById = (payment_id) => {
  return axios.get(`/payment/${payment_id}`).then((res) => {
    if (isObjectEmpty(res || {})) return null;
    return res;
  });
};

export const createPayment = () => {
  return axios
    .post("/payment", {
      url_redirect: `${process.env.REACT_APP_DOMAIN}/payment/cart/status`,
    })
    .then((res) => {
      if (isObjectEmpty(res || {})) return null;
      return res;
    });
};
