export const Avance = ({ courseModules, courseAdvance }) => {
  let totalAvan = 0;
  let cache = 0;

  courseModules?.forEach((courseModule) => {
    totalAvan += courseModule.classes.length;

    courseModule.quizId && totalAvan++;

    cache =
      courseAdvance?.classesAdvance?.length + courseAdvance?.examsAdvance?.length || 0;
  });

  const percAvan = Math.round((cache / totalAvan) * 100);

  return (
    <div
      className="progress-widget-container anim"
      style={{ "--delay": ".1s" }}
    >
      <div>
        <ul className="list-unstyled">
          <li style={{ fontSize: "12px", textTransform: "uppercase" }}>
            <small>Avance</small>
          </li>
          <li>
            <b className="perc-value">{percAvan}%</b>
          </li>
        </ul>
      </div>
      <div>
        <div className="progress-bar-h">
          <div
            className="progress-bar-perc"
            style={{ width: `${percAvan}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
