import React from 'react';
import { Link } from 'react-router-dom';
import { AvanceDashboard, CalificacionDashboard } from '../../../../components';

export function UserCourses({ id, title, isActive, finished, percAvan, percCal }) {
  return (
    <div className='course-card-container  mb-3 anim' style={{ '--delay': '.9s' }}>
      <div className='content'>
        {finished && (
          <div className='tag-container'>
            <div className='tag tag-disabled '>
              <span>Completado</span>
            </div>
          </div>
        )}
        <div className='top'>
          <div>
            <div className='course-name'>
              <span>{title}</span>
            </div>
            <div className='d-flex gap-1 bd-highlight mb-2'>
              <AvanceDashboard percAvan={percAvan} />
              <CalificacionDashboard percCal={percCal} />
            </div>
          </div>
        </div>
        <div className={`course-link w-100 p-2 ${!isActive ? 'course-link-disable' : ''}`}>
          <Link className='w-100' to={`/course/${id}`}>
            <span>{isActive ? "Ir al curso" : "Bloqueado"}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
