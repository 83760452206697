import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { imageUrl } from "../../../utils";
import { useCourse } from "../../../hooks";
import { Error, Loader } from "../../../components";
import { HeartIcon } from "../../../components/Icons/HeartIcon";
import {
  addItemToCart,
  addItemToFavorites,
  moveItemFromFavoritesToCart,
  removeItemFromFavorites,
} from "../../../store/slices/cart.slice";

import { imageUrl } from "../../../utils";

export const CourseDetails = () => {
  const user = useSelector((state) => state.user);
  const { id, loading, error, course } = useCourse({
    user,
    isProtected: false,
    userAdvance: false,
  });
  const dispatch = useDispatch();

  // const { isAuth } = useSelector((state) => state.app.auth);
  const { cart } = useSelector((state) => state);
  const { userCourses } = useSelector((state) => state);

  const userCourse = userCourses.some(
    (userCourse) => userCourse.course_id === Number(id)
  );
  const isInCart = cart?.cart?.some((item) => item.id === Number(id));
  const isAddedToFavorites = cart?.favorites?.some(
    (item) => item.id === Number(id)
  );

  const handleAddToCart = () => {
    if (isAddedToFavorites) {
      dispatch(moveItemFromFavoritesToCart(id));
    } else {
      dispatch(addItemToCart(id));
    }
  };

  const handleAddToFavorites = () => {
    if (isAddedToFavorites) {
      dispatch(removeItemFromFavorites(id));
    } else {
      dispatch(addItemToFavorites(id));
    }
  };

  if (loading) return <Loader delay={0} />;

  if (error) return <Error />;

  return (
    <>
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        Detalles del curso
      </div>

      <div
        className="course-detail content-block anim"
        style={{ "--delay": ".5s" }}
      >
        <div className="section-area section-sp1">
          <div className="container">
            <div className="row d-flex flex-row-reverse">
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 m-b30">
                <div className="course-detail-bx">
                  <div className="course-price">
                    <del>
                      {course?.discount > 0
                        ? course?.price
                        : course?.price_discount}
                    </del>
                    <h4 className="price">
                      ${" "}
                      {course?.discount > 0
                        ? course?.price_discount
                        : course?.price || 0}{" "}
                      <span style={{ fontSize: "16px" }}>MXN</span>
                    </h4>
                  </div>
                  <div className="course-buy-now text-center">
                    <button
                      onClick={handleAddToFavorites}
                      className="add-to-favorites-btn"
                    >
                      <HeartIcon fill={isAddedToFavorites} />
                    </button>
                    {userCourse ? (
                      <div className="user-course">
                        <b>Ya tienes este curso</b>
                        <br />
                        <Link className="button btn-to-dashboard" to="/">
                          Ir a mis cursos
                        </Link>
                      </div>
                    ) : (
                      <div>
                        <button
                          disabled={isInCart}
                          onClick={handleAddToCart}
                          className="btn btn-primary radius-xl mt-2 w-100 add-to-cart-btn"
                        >
                          {isInCart ? "En el carrito" : "Agregar al carrito"}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="teacher-bx">
                    <div className="teacher-info">
                      {/* HERE GOES TEACHER THUMB */}
                      {/* <div className='teacher-thumb'>
                        <img src={testiPic1} alt='' />
                      </div> */}
                      {/* HERE GOES TEACHER INFO DISPLAY */}
                      {/* <div className='teacher-name'>
                        <h5>El Tor Tax</h5>
                        <span>Electricidad y Magnetismo</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="cours-more-info">
                    {/* HERE GOES COURSE REVIEWS INFO */}
                    {/* <div className='review'>
                      <span>3 Reseñas</span>
                      <ul className='cours-star'>
                        <li className='active'><i className='fa fa-star' /></li>
                        <li className='active'><i className='fa fa-star' /></li>
                        <li className='active'><i className='fa fa-star' /></li>
                        <li><i className='fa fa-star' /></li>
                        <li><i className='fa fa-star' /></li>
                      </ul>
                    </div> */}
                    <div className="price categories">
                      <span>Categorias</span>
                      <h5 className="text-primary">
                        {course && course.category
                          ? course.category.name
                          : "Unaivalible"}
                      </h5>
                    </div>
                  </div>
                  <div className="course-info-list scroll-page">
                    <ul className="navbar">
                      <li>
                        <span>
                          <i className="ti-zip" /> Visión General
                        </span>
                      </li>
                      <li>
                        <span>
                          <i className="ti-bookmark-alt" /> Curriculum
                        </span>
                      </li>
                      {/* HERE GOES FAST SCROLL TO INSTRUCTOR & REVIEWS SECTIONS */}
                      {/* <li>
                        <ScrollTo smooth activeClass='active' spy className='nav-link' to='instructor'>
                          <i className='ti-user' /> Instructor
                        </ScrollTo>
                      </li>
                      <li>
                        <ScrollTo smooth activeClass='active' spy className='nav-link' to='reviews'>
                          <i className='ti-comments' /> Reseñas
                        </ScrollTo>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                <div className="courses-post">
                  <div className="ttr-post-media media-effect">
                    <img src={imageUrl(course?.thumb)} alt="Course portada" />
                    <div className="ttr-post-info">
                      <div className="ttr-post-title ">
                        <h2 className="post-title">
                          {course ? course.title : "No hay título disponible"}
                        </h2>
                      </div>
                      <div className="ttr-post-text">
                        <p>
                          {course
                            ? course.description
                            : "No hay descripcción breve disponible."}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="ttr-post-info m-b30">
                    <div className="ttr-post-title ">
                      <h2 className="post-title">
                        {course ? course.title : "No hay título disponible"}
                      </h2>
                    </div>
                    <div className="ttr-post-text">
                      <p>{course ? course.description : "No hay descripcción breve disponible."}</p>
                    </div>
                  </div> */}
                </div>
                <div className="courese-overview" id="overview">
                  <h4>Visión General</h4>
                  <div className="row">
                    <div className="col-md-12 col-lg-4">
                      <ul className="course-features">
                        <li>
                          <i className="ti-book" />{" "}
                          <span className="label">Temas</span>{" "}
                          {/* <span className="value">{topicsCounter(course)}</span> */}
                        </li>
                        <li>
                          <i className="ti-help-alt" />{" "}
                          <span className="label">Cuestionarios</span>{" "}
                          <span className="value">Sí</span>
                        </li>
                        <li>
                          <i className="ti-time" />{" "}
                          <span className="label">Duracion</span>{" "}
                          <span className="value">
                            {course?.duration_course
                              ? `${course.duration_course} h`
                              : "No data"}
                          </span>
                        </li>
                        {/* HERE ARE LISTED UNAVAILABLE DATA FOR THE MOMENT */}
                        {/* <li><i className='ti-stats-up' /> <span className='label'>Nivel</span> <span className='value'>{course && course.level ? course.level.name : "Unavailable"}</span></li>
                        <li><i className='ti-smallcap' /> <span className='label'>Idioma</span> <span className='value'>{course && course.lenguage ? course.lenguage.name : "Unavailable"}</span></li>
                        <li><i className='ti-user' /> <span className='label'>Estudiantes</span> <span className='value'>No data</span></li> */}
                        <li>
                          <i className="ti-check-box" />{" "}
                          <span className="label">Evaluaciones</span>{" "}
                          <span className="value">
                            {course?.certification ? "Sí" : "No"}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 col-lg-8">
                      <h5 className="m-b10">Descripción del Curso</h5>
                      <p>
                        {course
                          ? course.body
                          : "No hay descripcción larga disponible."}
                      </p>
                      <h5 className="m-b10">Certificación</h5>
                      <p>{course?.certification ? "Sí" : "No"}</p>
                      <h5 className="m-b10">Lo que Aprenderas</h5>
                      <ul className="list-checked primary">
                        <li>No data</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="m-b30" id="curriculum">
                  <h4>Temario</h4>
                  <ul className="curriculum-list">
                    {course && course?.modules
                      ? course.modules.map((item, i) => (
                          <li key={item.id}>
                            <h5>{item.title}</h5>
                            <ul>
                              {item.classes && item.classes.length
                                ? item.classes.map((item, i) => (
                                    <li key={item.id}>
                                      <div className="curriculum-list-box">
                                        <span>Tema {i + 1}.</span> {item.title}
                                      </div>
                                      <span>{item.duration}</span>
                                    </li>
                                  ))
                                : "No se han encontrado clases para este módulo"}
                            </ul>
                          </li>
                        ))
                      : "No se han encontrado módulos"}
                  </ul>
                </div>
                {/* HERE GOES INSTRUCTOR FULL INFO DISPLAY */}
                {/* <div className='' id='instructor'>
                  <h4>Instructor</h4>
                  <div className='instructor-bx'>
                    <div className='instructor-author'>
                      <img src={testiPic1} alt='' />
                    </div>
                    <div className='instructor-info'>
                      <h6>El Tor Tass </h6>
                      <span>Profesor</span>
                      <ul className='list-inline m-tb10'>
                        <li><Link to='#' className='btn sharp-sm facebook'><i className='fa fa-facebook' /></Link></li>
                        <li><Link to='#' className='btn sharp-sm twitter'><i className='fa fa-twitter' /></Link></li>
                        <li><Link to='#' className='btn sharp-sm linkedin'><i className='fa fa-linkedin' /></Link></li>
                        <li><Link to='#' className='btn sharp-sm google-plus'><i className='fa fa-google-plus' /></Link></li>
                      </ul>
                      <p className='m-b0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                  </div>
                  <div className='instructor-bx'>
                    <div className='instructor-author'>
                      <img src={testiPic2} alt='' />
                    </div>
                    <div className='instructor-info'>
                      <h6>Doña Lucha </h6>
                      <span>Profesora</span>
                      <ul className='list-inline m-tb10'>
                        <li><Link to='#' className='btn sharp-sm facebook'><i className='fa fa-facebook' /></Link></li>
                        <li><Link to='#' className='btn sharp-sm twitter'><i className='fa fa-twitter' /></Link></li>
                        <li><Link to='#' className='btn sharp-sm linkedin'><i className='fa fa-linkedin' /></Link></li>
                        <li><Link to='#' className='btn sharp-sm google-plus'><i className='fa fa-google-plus' /></Link></li>
                      </ul>
                      <p className='m-b0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                  </div>
                </div> */}
                {/* HERE GOES REVIEWS FULL INFO DISPLAY */}
                {/* <div className='' id='reviews'>
                  <h4>Reseñas</h4>
                  <div className='review-bx'>
                    <div className='all-review'>
                      <h2 className='rating-type'>3</h2>
                      <ul className='cours-star'>
                        <li className='active'><i className='fa fa-star' /></li>
                        <li className='active'><i className='fa fa-star' /></li>
                        <li className='active'><i className='fa fa-star' /></li>
                        <li><i className='fa fa-star' /></li>
                        <li><i className='fa fa-star' /></li>
                      </ul>
                      <span>3 Evaluación</span>
                    </div>
                    <div className='review-bar'>
                      <div className='bar-bx'>
                        <div className='side'>
                          <div>5 <i className='fa fa-star' /></div>
                        </div>
                        <div className='middle'>
                          <div className='bar-container'>
                            <div className='bar-5' style={{ width: '90%' }} />
                          </div>
                        </div>
                        <div className='side right'>
                          <div>150</div>
                        </div>
                      </div>
                      <div className='bar-bx'>
                        <div className='side'>
                          <div>4 <i className='fa fa-star' /></div>
                        </div>
                        <div className='middle'>
                          <div className='bar-container'>
                            <div className='bar-5' style={{ width: '70%' }} />
                          </div>
                        </div>
                        <div className='side right'>
                          <div>140</div>
                        </div>
                      </div>
                      <div className='bar-bx'>
                        <div className='side'>
                          <div>3 <i className='fa fa-star' /></div>
                        </div>
                        <div className='middle'>
                          <div className='bar-container'>
                            <div className='bar-5' style={{ width: '50%' }} />
                          </div>
                        </div>
                        <div className='side right'>
                          <div>120</div>
                        </div>
                      </div>
                      <div className='bar-bx'>
                        <div className='side'>
                          <div>2 <i className='fa fa-star' /></div>
                        </div>
                        <div className='middle'>
                          <div className='bar-container'>
                            <div className='bar-5' style={{ width: '40%' }} />
                          </div>
                        </div>
                        <div className='side right'>
                          <div>110</div>
                        </div>
                      </div>
                      <div className='bar-bx'>
                        <div className='side'>
                          <div>1 <i className='fa fa-star' /></div>
                        </div>
                        <div className='middle'>
                          <div className='bar-container'>
                            <div className='bar-5' style={{ width: '20%' }} />
                          </div>
                        </div>
                        <div className='side right'>
                          <div>80</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
