import axios from "./axios.service";
import { getUserToken } from "../utils";

export const updateUser = (userData) => {
  return axios.post("/user/register_curp", userData).then((res) => ({
    user: res.user,
    token: getUserToken(),
  }));
};

export const updateUserProfile = (userData) => {
  return axios.patch("/user", userData).then((res) => res);
};
