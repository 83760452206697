import axios from './axios.service';

export const getAllCourses = () => {
  return axios.get('/course/all').then((res) => res.courses);
};

export const getUserCourses = () => {
  return axios.get('/course/get_user_course').then((res) => res.courses);
};

export const getCourse = (id) => {
  return axios.get(`/course/${id}/full`).then((res) => res.course);
};
