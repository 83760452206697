import { useEffect, useState } from 'react';
import { setIsLoading, setMessage } from '../store/slices';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getExam } from '../services';
import { ROLES } from '../utils';

export function useExam({ user }) {
  const { idExam } = useParams();
  const loading = useSelector((state) => state?.app?.loading);
  const [error, setError] = useState(false);
  const [quiz, setQuiz] = useState({});
  // const [quiz, setQuiz] = useState(quizLocalApp);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let moduleExam = null;
    let moduleClasses = [];
    /* user.courses.forEach((course) => {
      const currentModule =
        course.modules.find((courseModule) => courseModule.quizId === Number(idExam)) || null;
      if (currentModule) {
        moduleExam = currentModule;
        moduleClasses = currentModule.classes;
      }
    }); */

    /* if (user.role !== ROLES.admin && !moduleExam) {
      dispatch(setMessage('No tienes acceso a este recurso.'));
      return navigate('/');
    } */

    /* if (
      user.role !== ROLES.admin &&
      moduleExam &&
    ) {
      dispatch(setMessage('Aun no puedes presentar este examen.'));
      return navigate('/');
    } */

    dispatch(setIsLoading(true));
    getExam(idExam)
      .then((quiz) => {
        if (quiz) {
          setQuiz(quiz);
        } else {
          dispatch(
            setMessage({
              type: 'error',
              message: 'El examen no existe en la base de datos',
            })
          );
          navigate('/');
        }
      })
      .catch((err) => {
        dispatch(
          setMessage({
            type: 'error',
            message: 'No fue posible obtener los recursos, por favor inténtelo nuevamente',
          })
        );
        setError(true);
      })
      .finally(() => dispatch(setIsLoading(false)));
  }, [idExam, dispatch, user.role, user.courses, navigate]);

  return { loading, error, idExam, quiz };
}
