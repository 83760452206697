export const CalificacionDashboard = ({ showAprovedExams = false, percCal, total = 0, approved = 0 }) => {
  return (
    <div
      className="progress-widget-container anim"
      style={{ "--delay": ".2s" }}
    >
      <div>
        <ul className="list-unstyled">
          <li style={{ fontSize: "12px", textTransform: "uppercase" }}>
            <small>Calificación</small>
          </li>
          <li>
            <b className="perc-value">{percCal}</b>
          </li>
        </ul>
      </div>
      {showAprovedExams && (
        <div>
          <ul className="list-unstyled">
            <li>
              <small style={{ fontSize: "10px", color: "#aaa" }}>
                <span>
                  Examenes Aprobados {approved} / {total}
                </span>
              </small>
            </li>
          </ul>
        </div>
      )}
      <div className="progress-bar-h">
        <div className="progress-bar-perc" style={{ width: `${percCal}%` }} />
      </div>
    </div>
  );
};
