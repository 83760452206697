import React from "react";
import error from "../assets/img/error.png";

export function Error({ message = "Intenta refrescar la página" }) {
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <img src={error} alt="Error" style={{ width: "100px", height: "100px" }} />
      <span style={{ fontWeight: "bold", color: "#ff2112" }}>{message}</span>
    </div>
  );
}
