import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditIcon } from "../../components/Icons/EditIcon";
import { EraseIcon } from "../../components/Icons/EraseIcon";
import { VisibleIcon } from "../../components/Icons/VisibleIcon";
import { NoVisibleIcon } from "../../components/Icons/NoVisibleIcon";
import { ModalLayout } from "../../components/ModalLayout";
import { ImageCropper } from "../../components/ImageCropper";
import { useModal } from "../../hooks/useModal";
import { updateUser } from "../../store/slices";
import { isObjectEmpty } from "../../utils";

const initialValues = {};

export const Profile = () => {
  const user = useSelector((state) => state.user);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();

  const dispatch = useDispatch();

  const isEditing = Object.values(edit).some((editing) => editing);

  const handleUpdateImageProfile = (imgSrc) => {
    setValues((prevValues) => {
      if (!edit.avatar) setEdit((prevEdit) => ({ ...prevEdit, avatar: true }));
      return { ...prevValues, avatar: imgSrc };
    });
  };

  const handleChangeValues = (e) => {
    setValues((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
  };

  const handleRemoveImage = () => {
    if (edit.avatar) setEdit((prevEdit) => ({ ...prevEdit, avatar: false }));
    setValues((prevValues) => ({ ...prevValues, avatar: null }));
  };

  const handleEdition = (property) => {
    setEdit((prevEdit) => {
      if (prevEdit) setValues((prevValues) => ({ ...prevValues, [property]: "" }));
      return {
        ...prevEdit,
        [property]: !!!prevEdit[property],
      };
    });
  };

  const handlePasswordVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  const handleCancelEdition = () => {
    setEdit({});
    setErrors({});
    setValues(initialValues);
  };

  const handleSubmit = async () => {
    let errs = {};
    for (const field in values) {
      if (typeof values[field] === "string" && edit[field] && values[field] === "") {
        errs = { ...errs, [field]: "Este campo es requerido." };
      }
    }
    setErrors(errs);

    if (!isObjectEmpty(errs)) return;

    const userData = new FormData();
    values.avatar && userData.append("avatar", values.avatar);
    values.telephone && userData.append("telephone", values.telephone);
    values.new_password && userData.append("new_password", values.new_password);

    const result = await dispatch(updateUser(userData));

    if (result) {
      setValues(initialValues);
      setErrors({});
      setEdit({});
      if (isVisible) setIsVisible(false);
    }
  };

  return (
    <section className="profile">
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        Mi perfil
      </div>

      <ModalLayout show={isOpen} handleCloseModal={handleCloseModal} title="Perfil">
        <ImageCropper
          handleUpdateImageProfile={handleUpdateImageProfile}
          handleClose={handleCloseModal}
        />
      </ModalLayout>

      <div className="col-12 anim" style={{ "--delay": ".5s" }}>
        <div className="row">
          <div className="profile-hero-container">
            <div className="profile-hero">
              {user?.avatar && !edit.avatar ? (
                <img alt="User profile" src={user.avatar} />
              ) : (
                <>
                  {values.avatar && (
                    <button className="cancel-img" onClick={handleRemoveImage}>
                      &times;
                    </button>
                  )}
                  <img
                    alt="User profile"
                    src={
                      values.avatar
                        ? URL.createObjectURL(values.avatar)
                        : "https://static.vecteezy.com/system/resources/previews/001/840/618/non_2x/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg"
                    }
                  />
                </>
              )}
              <button className="edit-img" onClick={handleOpenModal}>
                <EditIcon size={16} />
              </button>
            </div>
          </div>
          <div className="col-12">
            <div className="profile-item">
              <h4>Nombres:</h4>
              <span>{user?.name}</span>
            </div>
            <div className="profile-item">
              <h4>Apellidos:</h4>
              <span>{user?.lastname}</span>
            </div>
            <div className="profile-item">
              <h4>Correo eléctronico:</h4>
              <span>{user?.email}</span>
            </div>
            <div className="profile-item">
              <div className="item-header">
                <h4>Teléfono:</h4>
                <button onClick={() => handleEdition("telephone")}>
                  {!edit.telephone ? <EditIcon size={16} /> : <EraseIcon size={16} />}
                </button>
              </div>
              {edit.telephone ? (
                <div className="wrapper-item-input">
                  <div className="item-input">
                    <input
                      autoFocus
                      type="text"
                      name="telephone"
                      autoComplete="off"
                      placeholder="Ingrese el nuevo teléfono"
                      onChange={handleChangeValues}
                    />
                  </div>
                  {errors.telephone && <small>{errors.telephone}</small>}
                </div>
              ) : (
                <span>{user?.telephone || "n.a"}</span>
              )}
            </div>
            <div className="profile-item">
              <div className="item-header">
                <h4>Contraseña:</h4>
                <button onClick={() => handleEdition("new_password")}>
                  {!edit.new_password ? <EditIcon size={16} /> : <EraseIcon size={16} />}
                </button>
              </div>
              {edit.new_password && (
                <div className="wrapper-item-input">
                  <div className="item-input">
                    <input
                      autoFocus
                      autoComplete="off"
                      name="new_password"
                      type={isVisible ? "text" : "password"}
                      placeholder="Ingrese la nueva contraseña"
                      onChange={handleChangeValues}
                    />
                    <button onClick={handlePasswordVisibility}>
                      {!isVisible ? <VisibleIcon /> : <NoVisibleIcon />}
                    </button>
                  </div>
                  {errors.new_password && <small>{errors.new_password}</small>}
                </div>
              )}
            </div>
          </div>
        </div>

        {isEditing && (
          <div className="profile-actions">
            <button onClick={handleSubmit}>Guardar</button>
            <button onClick={handleCancelEdition}>Cancelar</button>
          </div>
        )}
      </div>
    </section>
  );
};
