export const HomeIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.7334 1.5H2.2334V5H5.7334V1.5Z"
        stroke="var(--icon-color)"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2334 1.5H7.7334V5H11.2334V1.5Z"
        stroke="var(--icon-color)"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2334 7H7.7334V10.5H11.2334V7Z"
        stroke="var(--icon-color)"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.7334 7H2.2334V10.5H5.7334V7Z"
        stroke="var(--icon-color)"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
