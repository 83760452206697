import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import { FaqItem } from "../../components/FaqItem";
// import { Footer, Header } from "../layouts";

export const Faqs = () => {
  return (
    <>
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        Preguntas frecuentes
      </div>

      <div className="container-fluid">
        <div className="row program-container flag-loaded">
          <div className="col-lg-6 col-md-12">
            <Accordion className="ttr-accordion m-b30 faq-bx">
              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="0">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Qué tipos de capacitaciones ofrecen?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="0">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      Ofrecemos capacitación en STPS federal y PC a tenemos
                                      registros en los 32 con sus respectivos municipios.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="1">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Qué validez tienen?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="1">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      Los certificados, tienen validez ante las dependencias del
                                      STPS y PC.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="2">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Cuánto tiempo duran las capacitaciones?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="2">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      La duración de las capacitaciones depende de cada una de las
                                      mismas, puede variar entre 40 y 1hra. Pero no te preocupes,
                                      puedes continuar con tu estudio dependiendo de tu tiempo.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="3">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Cómo se accede a las capacitaciones?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="3">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      Nuestra plataforma es en línea por lo tanto necesitas un
                                      equipo de cómputo y conexión a internet.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="4">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Cuál es el costo de las capacitaciones?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="4">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      El costo de cada capacitación depende de la complejidad del
                                      mismo.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>

          <div className="col-lg-6 col-md-12">
            <Accordion className="ttr-accordion m-b30 faq-bx">
              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="5">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Ofrecen algún tipo de garantía?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="5">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      Puedes validar nuestro registro en la siguiente página:
                                      <br />
                                      Sistema de Autorización y Registro como Agente Capacitador
                                      Externo
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="6">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Cómo puedo pagar por las capacitaciones?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="6">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      Las capacitaciones se pueden pagar en línea a través de la
                                      plataforma de mercado pago; Sin embargo, si tu empresa está
                                      interesada en adquirir más de un curso para más de una
                                      persona, puedes contactarnos para recibir mayor información.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="7">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Cómo puedo contactarlos si tengo alguna pregunta?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="7">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      A través de nuestro correo de contacto@procesoseguro.com
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="8">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Cómo puedo obtener un certificado de finalización?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="8">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      Al finalizar cada capacitación se te realizará un examen el
                                      cual tienes que acreditar con un mínimo de 8 para poder
                                      acceder a tu certificado.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row program-item-container">
                <div className="col-sm-12 program-item-content flag-challenges">
                  <div className="progress-bar-v anim" style={{ "--delay": ".1s" }}>
                    <div className="progress-bar-perc" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Accordion.Item eventKey="9">
                        <div className="program-item-headline anim" style={{ "--delay": ".3s" }}>
                          <div className="row">
                            <div className="col-sm-7 program-item-title-container program-item-title">
                              <div className="program-item-label">
                                <Accordion.Header className="stage-title">
                                  ¿Cuántas veces puedo reprobar un exámen?
                                </Accordion.Header>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Item>
                      <div className="row challenges-container">
                        <div className="col-sm-11 offset-sm-1 ">
                          <Accordion.Collapse eventKey="9">
                            <div className="row challenge-list">
                              <div className="col-sm-12 challenge-item-container">
                                <div className="faq-item-content" style={{ "--delay": ".4s" }}>
                                  <div className="row">
                                    <div className="program-item-col">
                                      Tienes hasta 3 oportunidades para poder pasar tu examen; En
                                      caso de reprobar las 3 oportunidades, tendrás que comprar de
                                      nuevo el curso.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
      </div>

      {/* <div className="page-content">
        <div className="content-block">
          <div className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-12 m-b30">
                  <div className="heading-bx left mb-4">
                    <h2 className="m-b10 title-head">
                      ¿Alguna <span> Pregunta?</span>
                    </h2>
                    <p className="m-b0">
                      It is a long established fact that a reader will be distracted by the readable
                      content of a page
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Accordion className="ttr-accordion m-b30 faq-bx">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        <div className="acod-title">¿Qué tipos de capacitaciones ofrecen?</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          Ofrecemos capacitación en STPS federal y PC a tenemos registros en los 32
                          con sus respectivos municipios.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="acod-title">¿Qué validez tienen?</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          Los certificados, tienen validez ante las dependencias del STPS y PC.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="2">
                        <div className="acod-title">¿Cuánto tiempo duran las capacitaciones?</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          La duración de las capacitaciones depende de cada una de las mismas, puede
                          variar entre 40 y 1hra. Pero no te preocupes, puedes continuar con tu
                          estudio dependiendo de tu tiempo.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="3">
                        <div className="acod-title">¿Cómo se accede a las capacitaciones?</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          Nuestra plataforma es en línea por lo tanto necesitas un equipo de cómputo
                          y conexión a internet.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="4">
                        <div className="acod-title">¿Cuál es el costo de las capacitaciones?</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          El costo de cada capacitación depende de la complejidad del mismo.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Accordion className="ttr-accordion m-b30 faq-bx">
                    <Card>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          <div className="acod-title">¿Ofrecen algún tipo de garantía?</div>
                        </Accordion.Header>
                      </Accordion.Item>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          Puedes validar nuestro registro en la siguiente página:
                          <br />
                          Sistema de Autorización y Registro como Agente Capacitador Externo
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        <div className="acod-title">¿Cómo puedo pagar por las capacitaciones?</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          Las capacitaciones se pueden pagar en línea a través de la plataforma de
                          mercado pago; Sin embargo, si tu empresa está interesada en adquirir más
                          de un curso para más de una persona, puedes contactarnos para recibir
                          mayor información.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="acod-title">
                          ¿Cómo puedo contactarlos si tengo alguna pregunta?
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          A través de nuestro correo de contacto@procesoseguro.com
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="2">
                        <div className="acod-title">
                          ¿Cómo puedo obtener un certificado de finalización?{" "}
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          Al finalizar cada capacitación se te realizará un examen el cual tienes
                          que acreditar con un mínimo de 8 para poder acceder a tu certificado.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="3">
                        <div className="acod-title">¿Cuántas veces puedo reprobar un exámen?</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          Tienes hasta 3 oportunidades para poder pasar tu examen; En caso de
                          reprobar las 3 oportunidades, tendrás que comprar de nuevo el curso.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="4">
                        <div className="acod-title">Cambiar nombre de Usuario</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          Lorem do solem aorem apsum dolor sit amet, adipiscing elit, sed diam nibh
                          euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="5">
                        <div className="acod-title">¿Puedo colgar mis cursos en esta pagina?</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          Lorem do solem aorem apsum dolor sit amet, adipiscing elit, sed diam nibh
                          euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
