import React from "react";
import Modal from "react-bootstrap/Modal";

export function ModalLayout({ children, show, handleCloseModal, title }) {
  return (
    <Modal className="modal-layout overlay" show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}
