import axios from "./axios.service";
import { isObjectEmpty } from "../utils/isObjectEmpty.util";

export const getUserCart = () => {
  return axios.get("/cart").then((res) => {
    if (isObjectEmpty(res)) return null;
    return res;
  });
};

export const addToCart = (course_id) => {
  return axios.post("/cart", { course_id }).then((res) => {
    if (isObjectEmpty(res)) return null;
    return res;
  });
};

export const moveFromCartToFavorites = (course_id) => {
  return axios.post("/cart/move/favorite", { course_id }).then((res) => {
    if (isObjectEmpty(res)) return null;
    return res;
  });
};

export const removeFromCart = (course_id) => {
  return axios.delete(`/cart/course/${course_id}`).then((res) => {
    if (isObjectEmpty(res)) return null;
    return res;
  });
};

export const addToFavorites = (course_id) => {
  return axios.post("/favorite", { course_id }).then((res) => {
    if (isObjectEmpty(res)) return null;
    return res;
  });
};

export const moveFromFavoritesToCart = (course_id) => {
  return axios.post("/favorite/move/cart", { course_id }).then((res) => {
    if (isObjectEmpty(res)) return null;
    return res;
  });
};

export const removeFromFavorites = (course_id) => {
  return axios.delete(`/favorite/course/${course_id}`).then((res) => {
    if (isObjectEmpty(res)) return null;
    return res;
  });
};
