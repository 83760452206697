import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CartEmpty } from "../../components/CartEmpty";
import { FavoriteItem } from "../../components/FavoriteItem";
import {
  moveItemFromFavoritesToCart,
  removeItemFromFavorites,
} from "../../store/slices/cart.slice";

export const Wishlist = () => {
  const { cart } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate("/courses");
  };

  const handleMoveToCart = (courseId) => {
    dispatch(moveItemFromFavoritesToCart(courseId));
  };

  const handleRemoveFromFavorites = (courseId) => {
    dispatch(removeItemFromFavorites(courseId));
  };

  return (
    <section className="wishlist-section">
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        Lista de deseados
      </div>

      <div className="col-12 anim" style={{ "--delay": ".5s" }}>
        <div className="payment-summary">
          <div className="wishlist cart">
            {!cart || cart?.favorites?.length === 0 ? (
              <CartEmpty handleClick={handleClick} />
            ) : (
              <ul className="cart-list">
                {cart?.favorites?.map((item) => (
                  <FavoriteItem
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    thumb={item.thumb}
                    price={item.price}
                    discount_price={item.price_discount}
                    handleMoveToCart={handleMoveToCart}
                    handleRemoveFromFavorite={handleRemoveFromFavorites}
                  />
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
