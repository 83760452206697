import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Masonry from 'react-masonry-component'

// Images
import EventImg1 from '../assets/img/event/pic1.jpg'
import EventImg2 from '../assets/img/event/pic2.jpg'
import EventImg3 from '../assets/img/event/pic3.jpg'
import EventImg4 from '../assets/img/event/pic4.jpg'

// Portfolio Content
const content = [
  {
    thumb: EventImg1,
    tag: ['Lo que esta Pasando'],
    title: 'Brigadas de Emergencia',
    date: 29,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
  },
  {
    thumb: EventImg2,
    tag: ['Lo que viene'],
    title: 'Brigadas Primeros Auxilios',
    date: 28,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
  },
  {
    thumb: EventImg3,
    tag: ['Eventos Anteriores'],
    title: 'Brigada Evacuación de Inmuebles',
    date: 27,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
  },
  {
    thumb: EventImg4,
    tag: ['Lo que esta Pasando'],
    title: 'Brigada contra Incendio',
    date: 26,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
  },
  {
    thumb: EventImg1,
    tag: ['Lo que viene'],
    title: 'Brigadas de Emergencia',
    date: 25,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
  },
  {
    thumb: EventImg2,
    tag: ['Eventos Anteriores'],
    title: 'Brigada Primeros Auxilios',
    date: 24,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
  }
]

const FilterList = ({ dataFilter, defaultTag, activeFilter }) => {
  return (
    <li className={`${activeFilter ? 'btn active' : 'btn'}`} onClick={() => defaultTag(dataFilter)}>
      <Link to='#'>{dataFilter}</Link>
    </li>
  )
}

const MasonryContent = () => {
  const [tag, setTag] = useState('Todos')
  const [filteredImages, setFilterdImages] = useState([])

  useEffect(() => {
    tag === 'Todos' ? setFilterdImages(content) : setFilterdImages(content.filter(image => image.tag.find(element => element === tag)))
  }, [tag])

  return (
    <>
      <div className='feature-filters clearfix center m-b40'>
        <ul className='filters' data-toggle='buttons'>
          <FilterList
            dataFilter='Todos'
            defaultTag={setTag}
            activeFilter={tag === 'Todos'}
          />
          <FilterList
            dataFilter='Lo que esta Pasando'
            defaultTag={setTag}
            activeFilter={tag === 'Lo que esta Pasando'}
          />
          <FilterList
            dataFilter='Lo que viene'
            defaultTag={setTag}
            activeFilter={tag === 'Lo que viene'}
          />
          <FilterList
            dataFilter='Eventos Anteriores'
            defaultTag={setTag}
            activeFilter={tag === 'Eventos Anteriores'}
          />
        </ul>
      </div>

      <Masonry className='ttr-gallery-listing magnific-image row'>
        {filteredImages.map((item, index) => (
          <div className='action-card col-lg-6 col-md-6 col-sm-12' key={index + 1}>
            <div className='event-bx m-b30'>
              <div className='action-box'>
                <img src={item.thumb} alt='' />
                <div className='event-time'>
                  <div className='event-date'>{item.date}</div>
                  <div className='event-month'>Junio</div>
                </div>
              </div>
              <div className='info-bx'>
                <div className='event-info'>
                  <ul className='media-post'>
                    <li><Link to='/event'><i className='fa fa-clock-o' /> 9:00am</Link></li>
                    <li><Link to='/event'><i className='fa fa-map-marker' /> Queretaro, Queretaro</Link></li>
                  </ul>
                  <h4 className='event-title'><Link to='/event'>{item.title}</Link></h4>
                  <p>{item.text}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Masonry>
    </>
  )
}

export const EventsContent = () => {
  return (
    <>
      <MasonryContent />
    </>
  )
}
