import axios from "./axios.service";

export const createClassAdvance = (advance) => {
  return axios
    .post("/course/advance_course", advance)
    .then((res) => res.advance);
};

export const getClassesAdvance = (courseId) => {
  return axios
    .get(`/course/get_course_advance/${courseId}`)
    .then((res) => res.advance);
};

export const getCourseAdvance = (courseId) => {
  return axios.get(`/course/${courseId}/information`).then((res) => res.information);
};

export const createQuizAdvance = (quizId, advance) => {
  return axios.post(`/quiz/${quizId}/information`, advance);
};

export const getQuizzesAdvance = (courseId) => {
  return axios
    .get(`/quiz/info_quiz/${courseId}`)
    .then((res) => res.QuizInformation);
};

export const updateQuizAdvance = (newAdvance) => {
  return axios.patch("/quiz/info_update_quiz", newAdvance);
};

export const retryCourse = (courseId) => {
  return axios.delete(`/course/retry_course/${courseId}`);
};