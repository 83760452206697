import React from 'react';
import { useSelector } from 'react-redux';
import { history, ROLES } from '../../../../utils';
import { useMemo } from 'react';

export function ModuleExam({ moduleQuizAdvance, courseModule }) {
  const user = useSelector((state) => state.user);
  const courseAdvance = useSelector((state) => state.courseAdvance);

  const examAdvance = useMemo(() => {
    return courseAdvance?.examsAdvance?.find(
      (examAdvance) => examAdvance?.section?.id === courseModule?.id && examAdvance?.section?.quiz?.id === courseModule?.quizId
    );
  }, [courseAdvance, courseModule]);

  const lastModuleClassAdvance = useMemo(() => {
    const classes = courseModule?.classes;

    return courseAdvance?.classesAdvance?.find(
      (currentClass) => currentClass?.lessonId === classes?.[classes?.length - 1]?.id
    );
  }, [courseModule.classes, courseAdvance.classesAdvance]);

  const isUnlocked = () => {
    if (user?.roleId === ROLES.admin) return false;

    if (!lastModuleClassAdvance) return true;
  };

  const toggleClass = () => {
    if (moduleQuizAdvance) {
      return 'fa fa-check-circle program-item-progress';
    } else {
      return 'fa fa-info-circle program-item-progress';
    }
  };

  return (
    <div className='col-sm-12 challenge-item-container'>
      <div className='challenge-item-content anim flag-last' style={{ '--delay': '.4s' }}>
        {isUnlocked() && <div className='challenge-item-content m-0 item-locked'></div>}
        <div className='row'>
          <div className='col-sm-7 program-item-title'>
            <div className='program-item-title-content'>
              <br />
              <i className={toggleClass()} />
              <b className='challenge-title'>Examen - módulo</b>
            </div>
          </div>
          <div className='col-sm-5 program-item-col text-right'>
            <ul className='list-unstyled list-inline'>
              <li className='list-inline-item'>
                {/* {examAdvance && <div className='challenge-item-content m-0 button-locked'></div>} */}
                <button
                  onClick={() => {
                    history.push(`/examen/${courseModule.quizId}`, {
                      slug: 'course quiz',
                      section_id: courseModule.id,
                    });
                  }}
                  style={{ visibility: examAdvance ? 'hidden' : 'visible' }}
                  className='btn btn-sm btn-dashboard'
                >
                  Examen
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
