import React, { useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

import logoDark from "../assets/img/logo.svg";

import { sendEmailRecoverPassword } from "../services";

const url = window.location.hostname;

const initialState = {
  email: "",
  url:
    url.includes("develop") || url.includes("localhost")
      ? "https://develop-dashboard.capacitacionps.com"
      : "https://dashboard.capacitacionps.com",
};

export function EmailForm({ handleChangeToLogIn }) {
  const [data, setData] = useState(initialState);

  const submitButtonRef = useRef(null);
  const emailInputRef = useRef(null);

  const handleOnChange = ({ name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    submitButtonRef.current.setAttribute("disabled", "");

    const sendEmail = sendEmailRecoverPassword(data);

    sendEmail
      .then(() => {
        setData(initialState);
        emailInputRef.current.blur();
      })
      .finally(() => {
        submitButtonRef.current.removeAttribute("disabled");
      });

    toast.promise(
      sendEmail,
      {
        loading: "Espera...",
        success: "Hemos enviado un correo de recuperación",
        error: "Ha ocurrido un error",
      },
      {
        duration: 5000,
      }
    );
  };

  return (
    <div className="login">
      <form className="login__form" onSubmit={handleOnSubmit}>
        <div className="login__logo">
          <img
            src={logoDark}
            alt="Proceso Seguro"
            className="login__logo-img"
          />
        </div>
        <div className="login__input-group">
          <input
            ref={emailInputRef}
            id="email"
            name="email"
            type="text"
            className="login__input"
            required={true}
            autoComplete="off"
            value={data.email}
            onChange={({ target }) => handleOnChange(target)}
          />
          <label htmlFor="email" className="login__input-label">
            Correo Electrónico
          </label>
        </div>

        <div className="login__form-btn recovery">
          <Link to="" onClick={handleChangeToLogIn}>
            Regresar
          </Link>
          <button
            ref={submitButtonRef}
            type="submit"
            className="btn btn-dark login-button"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
}
