import React, { useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from '../store/slices';
import { genAvatar } from '../utils';

export function Message() {
  const message = useSelector((state) => state.app.message);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!message) return;

    toast.remove();

    if (message.type === 'login') {
      toast(() => (
        <div className='wellcome'>
          <div className='wellcome__avatar'>
            {message.img ? (
              <img src={message.avatar} alt='User profile' className='img-fluid rounded-circle' />
            ) : (
              genAvatar(message.avatar)
            )}
          </div>
          <span className='wellcome__message'>{message.message}</span>
        </div>
      ));
    } else if (message.type === 'success') {
      toast.success(message.message);
    } else if (message.type === 'error') {
      toast.error(message.message, {
        style: {
          background: '#ffbfaa',
        },
      });
    } else if (message.type === 'logout') {
      toast.remove();
    }

    dispatch(clearMessage());
  }, [dispatch, message]);

  return (
    <Toaster
      containerStyle={{
        position: 'absolute',
        zIndex: '1000000',
      }}
    />
  );
}
