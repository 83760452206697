export const Calificacion = ({
  courseModules,
  courseAdvance,
  showAprovedExams,
}) => {
  let totalExams = courseModules?.filter(
    (courseModule) => courseModule.quizId !== null
  )?.length || 1;
  let average = 0;
  let cache = 0;

  courseAdvance.examsAdvance.forEach((examAdvance) => {
    if (examAdvance?.section?.quiz?.quizAdvances?.[0]?.score >= 80) cache += 1;
    average += examAdvance?.section?.quiz?.quizAdvances?.[0]?.score || 0;
  });

  const percCal = Math.round(average / totalExams);

  return (
    <div
      className="progress-widget-container anim"
      style={{ "--delay": ".2s" }}
    >
      <div>
        <ul className="list-unstyled">
          <li style={{ fontSize: "12px", textTransform: "uppercase" }}>
            <small>Calificación</small>
          </li>
          <li>
            <b className="perc-value">{percCal}</b>
          </li>
        </ul>
      </div>
      {showAprovedExams && (
        <div>
          <ul className="list-unstyled">
            <li>
              <small style={{ fontSize: "10px", color: "#aaa" }}>
                <span>
                  Examenes Aprobados {cache} / {totalExams}
                </span>
              </small>
            </li>
          </ul>
        </div>
      )}
      <div className="progress-bar-h">
        <div className="progress-bar-perc" style={{ width: `${percCal}%` }} />
      </div>
    </div>
  );
};
