import { useRef, useState } from "react";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import { AddImageIcon } from "./Icons/AddImageIcon";
import { setCanvasPreview } from "../utils/canvas.util";

import "react-image-crop/dist/ReactCrop.css";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

export const ImageCropper = ({ handleUpdateImageProfile, handleClose }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [error, setError] = useState("");
  const [crop, setCrop] = useState();

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;

        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("La imagen debe tener como mínimo 150 x 150 pixeles.");
          setImgSrc("");
          return;
        }
      });

      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);

    setCrop(centeredCrop);
  };

  const onAccept = () => {
    setCanvasPreview(
      handleUpdateImageProfile,
      imgRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );
    // const dataUrl = previewCanvasRef.current.toDataURL();
    // handleUpdateImageProfile(dataUrl);
    handleClose();
  };

  return (
    <>
      {!imgSrc && (
        <div className="upload-file-area">
          <label htmlFor="user-img">
            <AddImageIcon size={50} />
            Agregar imagen
          </label>
          <input
            id="user-img"
            type="file"
            name="user_img"
            multiple={false}
            accept="image/*"
            onChange={onSelectFile}
          />
        </div>
      )}

      {error && <p>{error}</p>}

      {imgSrc && (
        <div className="cropper-wrapper">
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            circularCrop
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ maxHeight: "70vh" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      )}

      {crop && <canvas ref={previewCanvasRef} style={{ display: "none" }} />}

      <div className="cropper-actions">
        {imgSrc && (
          <div className="reupload-file-button">
            <label htmlFor="reupload-user-img">Cambiar imagen</label>
            <input
              id="reupload-user-img"
              type="file"
              name="user_img"
              multiple={false}
              accept="image/*"
              onChange={onSelectFile}
            />
          </div>
        )}
        <button onClick={onAccept}>Aceptar</button>
        <button onClick={handleClose}>Cancelar</button>
      </div>
    </>
  );
};
