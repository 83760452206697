const initialAppState = {
  app: {
    loading: false,
    message: null,
    modalVideo: {
      isOpen: false,
      videoId: null,
    },
    modalSlideshow: {
      isOpen: false,
      slideId: null,
    },
  },
  user: null,
  userCourses: [],
  courseAdvance: {
    classesAdvance: [],
    examsAdvance: [],
  },
  course: {},
};

export const saveStateApp = (appState) => {
  try {
    const appStateString = JSON.stringify(appState);

    localStorage.setItem('PROCESO_SEGURO_DASHBOARD_APP_STATE', appStateString);
  } catch (err) {
    return;
  }
};

export const getStateApp = () => {
  try {
    const appStateString = localStorage.getItem('PROCESO_SEGURO_DASHBOARD_APP_STATE');

    if (!appStateString) return initialAppState;

    const appState = JSON.parse(appStateString);

    return appState;
  } catch (err) {
    return initialAppState;
  }
};

export const removeStateApp = () => {
  try {
    localStorage.removeItem('PROCESO_SEGURO_DASHBOARD_APP_STATE');
  } catch (err) {
    return;
  }
};
