import { createSlice } from "@reduxjs/toolkit";
import { setMessage } from "./app.slice";
import {
  getUserFromLocalStorage,
  history,
  setUserToLocalStorage,
  updateUserInLocalStorage,
} from "../../utils";
import { signInService, updateUserProfile } from "../../services";

const user = getUserFromLocalStorage();

export const userSlice = createSlice({
  name: "user",
  initialState: user,
  reducers: {
    setUser: (state, action) => action.payload,
    clearUser: () => null,
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const sigIn = (credentials) => {
  return function (dispatch) {
    return signInService(credentials).then((userAuth) => {
      setUserToLocalStorage(userAuth);

      dispatch(setUser(userAuth.user));

      history.replace("/");

      dispatch(
        setMessage({
          type: "login",
          img: userAuth.user.avatar ? true : false,
          avatar: userAuth.user.avatar
            ? userAuth.user.avatar
            : `${userAuth.user.name} ${userAuth.user.lastname}`,
          message: userAuth.user.username
            ? `Bienvenido/a ${userAuth.user.username}!`
            : `Bienvenido/a ${userAuth.user.name}!`,
        })
      );
    });
  };
};

export const updateUser = (userData) => {
  return function (dispatch) {
    return updateUserProfile(userData)
      .then((userUpdated) => {
        updateUserInLocalStorage(userUpdated.user);

        dispatch(setUser(userUpdated.user));

        dispatch(
          setMessage({
            type: "success",
            message: "Perfil actualizado correctamente.",
          })
        );

        return true;
      })
      .catch(() => {
        dispatch(
          setMessage({
            type: "error",
            message: "Ha ocurrido un error, inténtalo nuevamente.",
          })
        );

        return false;
      });
  };
};

export default userSlice.reducer;
