export const BooksIcon = () => {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="none">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <title>ionicons-v5-l</title>
        <rect
          x="32"
          y="96"
          width="64"
          height="368"
          rx="16"
          ry="16"
          style={{
            fil: "none",
            stroke: "#000000",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        ></rect>
        <line
          x1="112"
          y1="224"
          x2="240"
          y2="224"
          style={{
            fil: "none",
            stroke: "#000000",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        ></line>
        <line
          x1="112"
          y1="400"
          x2="240"
          y2="400"
          style={{
            fil: "none",
            stroke: "#000000",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        ></line>
        <rect
          x="112"
          y="160"
          width="128"
          height="304"
          rx="16"
          ry="16"
          style={{
            fil: "none",
            stroke: "#000000",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        ></rect>
        <rect
          x="256"
          y="48"
          width="96"
          height="416"
          rx="16"
          ry="16"
          style={{
            fil: "none",
            stroke: "#000000",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        ></rect>
        <path
          d="M422.46,96.11l-40.4,4.25c-11.12,1.17-19.18,11.57-17.93,23.1l34.92,321.59c1.26,11.53,11.37,20,22.49,18.84l40.4-4.25c11.12-1.17,19.18-11.57,17.93-23.1L445,115C443.69,103.42,433.58,94.94,422.46,96.11Z"
          style={{
            fil: "none",
            stroke: "#000000",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        ></path>
      </g>
    </svg>
  );
};
