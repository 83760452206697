import { Document } from '@react-pdf/renderer';
import CertificatePageOne from '../views/app/certificado/CertificatePageOne';
import { CertificatePageTwo } from '../views/app/certificado/CertificatePageTwo';

export function CertificatePDF({ user, course, courseAdvance, register }) {
  return (
    <Document>
      <CertificatePageOne
        user={user}
        course={course}
        courseAdvance={courseAdvance}
        register={register}
      />
      <CertificatePageTwo
        user={user}
        course={course}
        courseAdvance={courseAdvance}
        register={register}
      />
    </Document>
  );
}
