import React from 'react';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../../utils';
import { UserCourses } from './UserCourses';
import { Loader } from '../../../../components';

export function Courses() {
  const { loading } = useSelector((state) => state.app);
  const { user, userCourses } = useSelector((state) => state);

  if (loading) return <Loader delay={0.85} />;

  if (user?.roleId !== ROLES.admin && userCourses.length === 0) {
    return <div className='empty-courses'>Aún no has adquirido ninguno de nuestros cursos.</div>;
  }

  return (
    <div className='col-sm-12 global-hide'>
      <div className='row'>
        {userCourses.map((userCourse) => (
          <UserCourses
          key={userCourse.id}
          id={userCourse.course_id}
          title={userCourse.course_title || "Curso - Capacitación"}
          isActive={userCourse.is_active}
          finished={userCourse.finished}
          percAvan={userCourse.percentage_course}
          percCal={userCourse.qualification_course}
        />
        ))}
      </div>
    </div>
  );
}
