import React from 'react'

export function Pagination({ paginationRange, currentPage, pages, handleChangePage }) {
  return (
     <div className='col-lg-12 m-b20 h-100'>
        <div className='pagination-bx rounded-sm gray clearfix h-100'>
           <ul className='pagination h-100'>
              {currentPage > 1 && (
                 <li className='previous'>
                    <button to='#' onClick={() => handleChangePage(currentPage - 1)}>
                       <i className='ti-arrow-left' />
                    </button>
                 </li>
              )}
              {paginationRange.map((page) => (
                 <li key={page} className={page === currentPage ? 'active' : null}>
                    <button onClick={() => handleChangePage(page)} disabled={page === currentPage}>
                       {page}
                    </button>
                 </li>
              ))}
              {currentPage < pages && (
                 <li className='next'>
                    <button onClick={() => handleChangePage(currentPage + 1)}>
                       <i className='ti-arrow-right' />
                    </button>
                 </li>
              )}
           </ul>
        </div>
     </div>
  );
}