import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoDark from '../assets/img/logo.svg';
import { useAuth } from '../hooks';

const initialState = {
  email: '',
  password: '',
};

export function LoginForm({ handleChangeToRecoverPassword }) {
  const [data, setData] = useState(initialState);
  const [signInError, setSignInError] = useState('');

  const { logIn } = useAuth();

  const handleOnChange = ({ name, value }) => {
    setData({ ...data, [name]: value });
    if (signInError) setSignInError('');
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    logIn(data).catch((err) => {
      setSignInError('Credenciales incorrectas.');
    });
  };

  return (
    <div className='login'>
      <form className='login__form' onSubmit={handleOnSubmit}>
        <div className='login__logo'>
          <img src={logoDark} alt='Proceso Seguro' className='login__logo-img' />
        </div>
        <div className='login__input-group'>
          <input
            id='email'
            name='email'
            type='text'
            className='login__input'
            required={true}
            autoComplete='off'
            value={data.email}
            onChange={({ target }) => handleOnChange(target)}
          />
          <label htmlFor='email' className='login__input-label'>
            Correo Electrónico
          </label>
        </div>
        <div className='login__input-group'>
          <input
            id='password'
            name='password'
            type='password'
            className='login__input'
            autoComplete='off'
            required={true}
            value={data.password}
            onChange={({ target }) => handleOnChange(target)}
          />
          <label htmlFor='password' className='login__input-label'>
            Contraseña
          </label>
        </div>

        {signInError && (
          <div className='login__error'>
            <small className='text-danger'>{signInError}</small>
          </div>
        )}

        <div className='login__link-recovery'>
          <Link to='' onClick={handleChangeToRecoverPassword}>
            Olvidé mi contraseña
          </Link>
        </div>

        <div className='login__form-btn'>
          <button className='btn btn-dark login-button'>Ingresar</button>
        </div>
      </form>
    </div>
  );
}
