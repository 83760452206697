export const setUserToLocalStorage = (userAuth) => {
  try {
    const user = JSON.stringify(userAuth);

    localStorage.setItem("PROCESO_SEGURO_DASHBOARD_USER_APP", user);
  } catch (err) {
    return;
  }
};

export const getUserFromLocalStorage = () => {
  try {
    const userString = localStorage.getItem("PROCESO_SEGURO_DASHBOARD_USER_APP");

    if (!userString) return null;

    const user = JSON.parse(userString);

    return user.user;
  } catch (err) {
    return null;
  }
};

export const updateUserInLocalStorage = (userData) => {
  try {
    let userString = localStorage.getItem("PROCESO_SEGURO_DASHBOARD_USER_APP");

    if (!userString) return null;

    const user = JSON.parse(userString);

    user.user = userData;

    userString = JSON.stringify(user);

    localStorage.setItem("PROCESO_SEGURO_DASHBOARD_USER_APP", userString);
  } catch (err) {
    return;
  }
};

export const getUserToken = () => {
  try {
    const userString = localStorage.getItem("PROCESO_SEGURO_DASHBOARD_USER_APP");

    if (!userString) return null;

    const user = JSON.parse(userString);

    return user.token;
  } catch (err) {
    return null;
  }
};

export const removeUserFromLocalStorage = () => {
  try {
    localStorage.removeItem("PROCESO_SEGURO_DASHBOARD_USER_APP");
  } catch (err) {
    return;
  }
};
