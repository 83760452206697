import { createSlice } from "@reduxjs/toolkit";
import {
  createClassAdvance,
  createQuizAdvance,
  getClassesAdvance,
  getCourseAdvance,
  getQuizzesAdvance,
  retryCourse,
} from "../../services";
import { setIsLoadingExamResult, setIsLoadingItemAdvance, setMessage } from "./app.slice";
import { updateUserCourses } from "./userCourses.slice";
import { updateCourseAdvance } from "./course.slice";

export const courseAdvanceSlice = createSlice({
  name: "courseAdvance",
  initialState: {
    classesAdvance: [],
    examsAdvance: [],
    examResult: null,
  },
  reducers: {
    setCourseAdvance: (state, action) => ({
      classesAdvance: action.payload.classesAdvance,
      examsAdvance: action.payload.examsAdvance,
      examResult: state.examResult,
    }),
    setExamResult: (state, action) => ({
      examResult: action.payload,
    }),
    clearCourseAdvance: () => ({
      classesAdvance: [],
      examsAdvance: [],
    }),
    clearExamResult: (state) => ({
      classesAdvance: state.classesAdvance,
      examsAdvance: state.examsAdvance,
      examResult: null,
    }),
  },
});

export const { setCourseAdvance, clearCourseAdvance, setExamResult, clearExamResult } =
  courseAdvanceSlice.actions;

export const allCourseAdvance = (courseId) => {
  return (dispatch) => {
    return Promise.all([
      getClassesAdvance(courseId),
      getQuizzesAdvance(courseId),
      getCourseAdvance(courseId),
    ]).then((advance) => {
      dispatch(
        setCourseAdvance({
          classesAdvance: advance[0],
          examsAdvance: advance[1],
        })
      );
      dispatch(updateCourseAdvance(advance[2]));
    });
  };
};

export const generateClassAdvance = (advance) => {
  return (dispatch) => {
    dispatch(setIsLoadingItemAdvance({
      loading: true,
      sectionId: advance?.sectionId || null,
      lessonId: advance?.lessonId || null,
    }));
    return createClassAdvance(advance)
      .then(() => {
        dispatch(allCourseAdvance(advance.courseId))
        dispatch(updateUserCourses());
      })
      .catch((err) => {
        if (err.response.status === 401) return;

        dispatch(
          setMessage({
            type: "error",
            message:
              "No fue posible registrar el avance de la clase, por favor inténtelo nuevamente",
          })
        );
      })
      .then(() => {
        dispatch(setIsLoadingItemAdvance({
          loading: false,
          sectionId: null,
          lessonId: null,
        }));
      });
  };
};

export const generateExamAdvance = (quizId, advance) => {
  return (dispatch) => {
    dispatch(setIsLoadingExamResult(true));
    return createQuizAdvance(quizId, advance)
      .then((data) => {
        dispatch(allCourseAdvance(advance.courseId));
        dispatch(setExamResult(data));
        dispatch(updateUserCourses())
      })
      .catch((err) => {
        if (err.response.status === 401) return;

        dispatch(
          setMessage({
            type: "error",
            message:
              "No fue posible guardar tu resultado, por favor inténtelo nuevamente",
          })
        );
      })
      .finally(() => {
        dispatch(setIsLoadingExamResult(false));
      });
  };
};

export const updateExamAdvance = (newAdvance) => {};

export const resetCourseAdvance = (courseId) => {
  return (dispatch) => {
    return retryCourse(courseId)
      .then(() => dispatch(allCourseAdvance(courseId)))
      .catch((err) => {
        if (err.response.status === 401) return;

        dispatch(
          setMessage({
            type: "error",
            message:
              "No fue posible realizar esta acción, por favor inténtelo nuevamente.",
          })
        );
      });
  };
};

export default courseAdvanceSlice.reducer;
