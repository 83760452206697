import { Link } from "react-router-dom";
import logoDark from "../assets/img/logo.svg";

export const NewPasswordForm = ({ ...props }) => {
  return (
    <form className="login__form" onSubmit={props.handleOnSubmit}>
      <div className="login__logo">
        <img src={logoDark} alt="Proceso Seguro" className="login__logo-img" />
      </div>
      <div className="login__input-group">
        <input
          id="new_password"
          name="new_password"
          type="password"
          className="login__input"
          autoComplete="off"
          required={true}
          value={props.data.password}
          onChange={({ target }) => props.handleOnChange(target)}
        />
        <label htmlFor="password" className="login__input-label">
          Nueva Contraseña
        </label>
      </div>

      <div className="login__link-recovery">
        <Link to="login">Iniciar Sesión</Link>
      </div>
      <div className="login__form-btn">
        <button
          type="submit"
          className="btn btn-dark login-button"
          disabled={props.isLoading}
        >
          Enviar
        </button>
      </div>
    </form>
  );
};
