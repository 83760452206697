import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    loadingItemAdvance: {
      loading: false,
      sectionId: null,
      lessonId: null,
    },
    loadingExamResult: false,
    message: null,
    modalVideo: {
      isOpen: false,
      videoId: null,
    },
    modalSlideshow: {
      isOpen: false,
      slideId: null,
    },
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsLoadingItemAdvance: (state, action) => {
      state.loadingItemAdvance = action.payload;
    },
    setIsLoadingExamResult: (state, action) => {
      state.loadingExamResult = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    openModalVideo: (state, action) => {
      state.modalVideo.isOpen = true;
      state.modalVideo.videoId = action.payload;
    },
    closeModalVideo: (state) => {
      state.modalVideo.isOpen = false;
      state.modalVideo.videoId = null;
    },
    openModalSlideshow: (state, action) => {
      state.modalSlideshow.isOpen = true;
      state.modalSlideshow.slideId = action.payload;
    },
    closeModalSlideshow: (state) => {
      state.modalSlideshow.isOpen = false;
      state.modalSlideshow.slideId = null;
    },
  },
});

export const {
  setIsLoading,
  setIsLoadingItemAdvance,
  setIsLoadingExamResult,
  setMessage,
  clearMessage,
  openModalVideo,
  closeModalVideo,
  openModalSlideshow,
  closeModalSlideshow,
} = appSlice.actions;
export default appSlice.reducer;
