import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CartEmpty } from "../../components/CartEmpty";
import { CartItem } from "../../components/CartItem";
import { moveItemFromCartToFavorites, removeItemFromCart } from "../../store/slices/cart.slice";
import { setMessage } from "../../store/slices";
import { createPayment } from "../../services";
import { currencyFormater } from "../../utils";

export const Cart = () => {
  const [isSettingPayment, setIsSettingPayment] = useState(false);
  const { cart } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate("/courses");
  };

  const handleMoveToFavorites = (courseId) => {
    dispatch(moveItemFromCartToFavorites(courseId));
  };

  const handleRemoveFromCart = (courseId) => {
    dispatch(removeItemFromCart(courseId));
  };

  const handlePayment = async () => {
    setIsSettingPayment(true);
    try {
      const res = await createPayment();
      if (res?.data?.init_point) {
        window.location.href = res.data.init_point;
      }
    } catch (err) {
      dispatch(
        setMessage({
          type: "error",
          message: "Ha ocurrido un error, inténtelo nuevamente más tarde.",
        })
      );
      setIsSettingPayment(false);
    }
  };

  return (
    <section className="cart-section">
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        Carrito
      </div>

      <div className="col-12 anim" style={{ "--delay": ".5s" }}>
        <div className="payment-summary">
          <div className="cart">
            {!cart || cart?.cart?.length === 0 ? (
              <CartEmpty handleClick={handleClick} />
            ) : (
              <>
                <ul className="cart-list">
                  {cart?.cart?.map((item) => (
                    <CartItem
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      thumb={item.thumb}
                      price={item.price}
                      discount_price={item.price_discount}
                      handleMoveToFavorites={handleMoveToFavorites}
                      handleRemoveFromCart={handleRemoveFromCart}
                    />
                  ))}
                </ul>

                <div className="checkout-summary">
                  <h4>Resumen</h4>
                  <div className="summary-item">
                    <h5>Subtotal</h5>
                    <span>{currencyFormater.format(cart?.sub_total)}</span>
                  </div>
                  <div className="summary-item">
                    <h5>Descuento %</h5>
                    <span>{cart?.promotion_percentage} %</span>
                  </div>
                  <div className="summary-item">
                    <h5>Total descuento</h5>
                    <span>{currencyFormater.format(cart?.total_promotion)}</span>
                  </div>
                  <div className="summary-item">
                    <h5>Total</h5>
                    <span>{currencyFormater.format(cart?.total)}</span>
                  </div>
                </div>

                <button
                  disabled={isSettingPayment}
                  onClick={handlePayment}
                  className="checkout-button"
                >
                  {isSettingPayment ? <span>Generando pago...</span> : <span>Pagar</span>}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
