import { createSlice } from "@reduxjs/toolkit";
import {
  addToCart,
  addToFavorites,
  getUserCart,
  moveFromCartToFavorites,
  moveFromFavoritesToCart,
  removeFromCart,
  removeFromFavorites,
} from "../../services";
import { setIsLoading, setMessage } from "./app.slice";

const initialState = null;

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action) => action.payload,
    clearCart: () => initialState,
  },
});

export const { setCart, clearCart } = cartSlice.actions;

export const getInitialUserCart = () => {
  return (dispatch) => {
    dispatch(setIsLoading(true));
    getUserCart()
      .then((cart) => {
        dispatch(setCart(cart));
      })
      .catch((err) => {
        if (err.response.status === 401) return;

        dispatch(
          setMessage({
            type: "error",
            message: "No fue posible obtener el carrito",
          })
        );
      })
      .finally(() => dispatch(setIsLoading(false)));
  };
};

export const addItemToCart = (course_id) => {
  return async function (dispatch) {
    return addToCart(course_id)
      .then((cart) => {
        dispatch(setCart(cart));
      })
      .catch((err) => {
        dispatch(
          setMessage({
            type: "error",
            message: "Ha ocurrido un error.",
          })
        );
      });
  };
};

export const moveItemFromCartToFavorites = (course_id) => {
  return async function (dispatch) {
    return moveFromCartToFavorites(course_id)
      .then((cart) => {
        dispatch(setCart(cart));
      })
      .catch((err) => {
        dispatch(
          setMessage({
            type: "error",
            message: "Ha ocurrido un error.",
          })
        );
      });
  };
};

export const removeItemFromCart = (course_id) => {
  return async function (dispatch) {
    return removeFromCart(course_id)
      .then((cart) => {
        dispatch(setCart(cart));
      })
      .catch((err) => {
        dispatch(
          setMessage({
            type: "error",
            message: "Ha ocurrido un error.",
          })
        );
      });
  };
};

export const addItemToFavorites = (course_id) => {
  return async function (dispatch) {
    return addToFavorites(course_id)
      .then((cart) => {
        dispatch(setCart(cart));
      })
      .catch((err) => {
        dispatch(
          setMessage({
            type: "error",
            message: "Ha ocurrido un error.",
          })
        );
      });
  };
};

export const moveItemFromFavoritesToCart = (course_id) => {
  return async function (dispatch) {
    return moveFromFavoritesToCart(course_id)
      .then((cart) => {
        dispatch(setCart(cart));
      })
      .catch((err) => {
        dispatch(
          setMessage({
            type: "error",
            message: "Ha ocurrido un error.",
          })
        );
      });
  };
};

export const removeItemFromFavorites = (course_id) => {
  return async function (dispatch) {
    return removeFromFavorites(course_id)
      .then((cart) => {
        dispatch(setCart(cart));
      })
      .catch((err) => {
        dispatch(
          setMessage({
            type: "error",
            message: "Ha ocurrido un error.",
          })
        );
      });
  };
};

export default cartSlice.reducer;
