import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  generateClassAdvance,
  openModalSlideshow,
  openModalVideo,
} from "../../../../store/slices";
import { ROLES } from "../../../../utils";

export function useModuleClass({
  previousModuleQuizAdvance,
  prevCourseModule,
  courseModule,
  moduleClass,
  moduleIndex,
  moduleClassIndex,
}) {
  const { idCourse } = useParams();
  const user = useSelector((state) => state.user);
  const loadingItemAdvance = useSelector((state) => state.app.loadingItemAdvance);
  const courseAdvance = useSelector((state) => state.courseAdvance);
  const [isSlideChecked, setIsSlideChecked] = useState(false);
  const [isVideoChecked, setIsVideoChecke] = useState(false);

  useEffect(() => {
    if (
      courseAdvance?.classesAdvance?.length === 0 &&
      courseAdvance?.examsAdvance?.length === 0
    ) {
      setIsSlideChecked(false);
    }
  }, [courseAdvance]);

  const dispatch = useDispatch();

  const classAdvance = useMemo(
    () =>
      courseAdvance?.classesAdvance?.find(
        (currentClass) => currentClass.lessonId === moduleClass.id
      ),
    [courseAdvance.classesAdvance, moduleClass.id]
  );

  const previousClassAdvance = useMemo(
    () =>
      courseAdvance?.classesAdvance?.find((currentClass) => {
        if (moduleIndex > 0 && moduleClassIndex === 0) {
          return (
            currentClass.lessonId ===
            prevCourseModule?.classes?.[prevCourseModule.classes.length - 1]?.id
          );
        }
        return currentClass.lessonId === courseModule.classes[moduleClassIndex - 1]?.id;
      }),
    [courseAdvance.classesAdvance, courseModule, moduleClassIndex, moduleIndex, prevCourseModule]
  );

  const newAdvance = {
    courseId: idCourse,
    sectionId: courseModule.id,
    lessonId: moduleClass.id,
    isComplete: true,
  };

  const checkSlide = () => {
    if (user?.roleId !== ROLES.user) return;
    if (!isSlideChecked) setIsSlideChecked(true);
    if (
      (moduleClass.video && isVideoChecked && !classAdvance) ||
      (moduleClass.slide && !classAdvance && !moduleClass.video)
    ) {
      dispatch(generateClassAdvance(newAdvance));
    }
  };

  const checkVideo = () => {
    if (user?.roleId !== ROLES.user) return;
    if (!isVideoChecked) setIsVideoChecke(true);
    if (
      (moduleClass.slide && isSlideChecked && !classAdvance) ||
      (moduleClass.video && !classAdvance && !moduleClass.slide)
    ) {
      dispatch(generateClassAdvance(newAdvance));
    }
  };

  const toggleClass = () => {
    if (classAdvance) {
      return "fa fa-check-circle program-item-progress";
    } else {
      return "fa fa-info-circle program-item-progress";
    }
  };

  const isUnlocked = () => {
    if (user?.roleId === ROLES.admin) return false;

    if (moduleIndex === 0 && moduleClassIndex === 0) {
      return false;
    }

    if (moduleIndex > 0 && moduleClassIndex === 0 && prevCourseModule?.quizId === null && previousClassAdvance) {
      return false;
    }

    if (
      moduleIndex > 0 &&
      moduleClassIndex === 0 &&
      previousModuleQuizAdvance
    ) {
      return false;
    }

    if (
      moduleClassIndex > 0 &&
      previousClassAdvance
    ) {
      return false;
    }

    /* if (!previousClassAdvance) {
      return true;
    } */

    return true;
  };

  const handleClickVideo = () => {
    dispatch(openModalVideo(moduleClass?.video));
    checkVideo();
  };

  const handleClickSlide = () => {
    dispatch(openModalSlideshow(moduleClass?.slide));
    checkSlide();
  };

  return {
    loadingItemAdvance,
    toggleClass,
    isUnlocked,
    handleClickVideo,
    handleClickSlide,
  };
}
