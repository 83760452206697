import axios from 'axios';
import {
  getConfig,
  getUserToken,
  history,
  removeStateApp,
  removeUserFromLocalStorage,
} from '../utils';
import { store } from '../store/index';
import { setMessage } from '../store/slices/app.slice';
import { clearUser } from '../store/slices';
// import { setLoading } from '../redux/actions';
// import { error as messageError } from './message';

let instance = axios.create({ baseURL: process.env.REACT_APP_URL_API });

let numLoadings = 0;
const requestHandler = (request) => {
  request.headers = getConfig();
  numLoadings++;
  // store.dispatch(setLoading(true));
  return request;
};

const responseHandler = (response) => {
  if (--numLoadings === 0) {
    // store.dispatch(setLoading(false));
  }

  if (response && response.data) {
    return response.data;
  }

  return response;
};

const errorHandler = (err) => {
  if (!--numLoadings) {
    // store.dispatch(setLoading(false));
  }
  // messageError('Falló tu conexión, vuelve a intentarlo', 3000);
  const token = getUserToken();

  if (err.response.status === 401 && token) {
    removeUserFromLocalStorage();
    removeStateApp();

    store.dispatch(clearUser());

    history.replace('/login');

    store.dispatch(
      setMessage({
        type: 'error',
        message: 'Sesión expirada. Inicie sesión nuevamente',
      })
    );
  }

  return Promise.reject(err);
};

instance.interceptors.request.use((request) => requestHandler(request));
instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default instance;
