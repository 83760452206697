import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useUserForm } from './hooks/useUserForm';

export function UserForm() {
  const {
    userFormRef,
    initialValues,
    userFormSchema,
    states,
    towns,
    occupations,
    specificOccupations,
    handleSubmit,
    handleCancell,
  } = useUserForm();

  return (
    <Formik
      innerRef={userFormRef}
      initialValues={initialValues}
      validationSchema={userFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, resetForm }) => (
        <Form className='form__user'>
          <div className='form__user-container'>
            <div className='form-row'>
              <div className='col'>
                <label htmlFor='form__user-curp' className='form-label input-label'>
                  <i className='fa-solid fa-address-card'></i> CURP
                </label>
                <Field
                  type='text'
                  id='form__user-curp'
                  className='form-control'
                  name='curp'
                  autoComplete='off'
                />
                <ErrorMessage name='curp' component='small' className='form-error-message' />
              </div>
            </div>

            <div className='form-row'>
              <div className='col'>
                <label className='form-label input-label'>Estado</label>
                <Field as='select' className='form-select' name='stateId'>
                  <option values=''>Seleccione un estado</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name='stateId' component='small' className='form-error-message' />
              </div>
            </div>

            {values.stateId && (
              <div className='form-row'>
                <div className='col'>
                  <label className='form-label input-label'>Municipio</label>
                  <Field as='select' className='form-select' name='townId'>
                    <option>Seleccione un municipio</option>
                    {towns.map((town) => (
                      <option key={town.id} value={town.id}>
                        {town.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name='townId' component='small' className='form-error-message' />
                </div>
              </div>
            )}

            <div className='form-row'>
              <div className='col'>
                <label className='form-label input-label'>Ocupación</label>
                <Field as='select' className='form-select' name='market_stall'>
                  <option values=''>Seleccione una ocupación</option>
                  {occupations.map((occupation) => (
                    <option key={occupation.id} value={occupation.clave}>
                      {occupation.name_ocupation}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name='market_stall'
                  component='small'
                  className='form-error-message'
                />
              </div>
            </div>

            {values.market_stall && (
              <div className='form-row'>
                <div className='col'>
                  <label className='form-label input-label'>Puesto</label>
                  <Field as='select' className='form-select' name='specific_occupation'>
                    <option values=''>Seleccione una ocupación específica</option>
                    {specificOccupations.map((specificOccupation) => (
                      <option key={specificOccupation.id} value={specificOccupation.clave}>
                        {specificOccupation.name_ocupation}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name='specific_occupation'
                    component='small'
                    className='form-error-message'
                  />
                </div>
              </div>
            )}
          </div>

          <div className='form__user-footer'>
            <button type='sumbit' className='btn '>
              Enviar
            </button>
            <button
              type='button'
              onClick={() => handleCancell(resetForm)}
              className='btn btn-cancell'
            >
              Cancelar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}


/* import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useUserForm } from './hooks/useUserForm';

export function UserForm() {
  const { userFormRef, initialValues, userFormSchema, states, towns, handleSubmit, handleCancell } =
    useUserForm();

  return (
    <Formik
      innerRef={userFormRef}
      initialValues={initialValues}
      validationSchema={userFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, resetForm }) => (
        <Form className='form__user'>
          <div className='form__user-container'>
            <div className='form-row'>
              <div className='col'>
                <label htmlFor='form__user-curp' className='form-label input-label'>
                  <i className='fa-solid fa-address-card'></i> CURP
                </label>
                <Field
                  type='text'
                  id='form__user-curp'
                  className='form-control'
                  name='curp'
                  autoComplete='off'
                />
                <ErrorMessage name='curp' component='small' className='form-error-message' />
              </div>
            </div>

            <div className='form-row'>
              <div className='col'>
                <label className='form-label input-label'>Estado</label>
                <Field as='select' className='form-select' name='stateId'>
                  <option values=''>Seleccione un estado</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name='stateId' component='small' className='form-error-message' />
              </div>
            </div>

            {values.stateId && (
              <div className='form-row'>
                <div className='col'>
                  <label className='form-label input-label'>Municipio</label>
                  <Field as='select' className='form-select' name='townId'>
                    <option>Seleccione un municipio</option>
                    {towns.map((town) => (
                      <option key={town.id} value={town.id}>
                        {town.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name='townId' component='small' className='form-error-message' />
                </div>
              </div>
            )}
          </div>

          <div className='form__user-footer'>
            <button type='sumbit' className='btn '>
              Enviar
            </button>
            <button
              type='button'
              onClick={() => handleCancell(resetForm)}
              className='btn btn-cancell'
            >
              Cancelar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
} */
