import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Message, NewPasswordForm } from "../components";

import { changeUserPassword } from "../services";

import { setMessage } from "../store/slices";

const initialState = {
  new_password: "",
};

export const Recover = () => {
  const [data, setData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnChange = ({ name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    const url = window.location.pathname;
    const token = url.replace("/password-recovery/", "");

    try {
      setIsLoading(true);

      await changeUserPassword({ ...data, token });

      navigate("/login");

      setIsLoading(false);

      dispatch(
        setMessage({
          type: "success",
          message: "Contraseña actualizada",
        })
      );
    } catch (error) {
      setIsLoading(false);

      dispatch(
        setMessage({
          type: "error",
          message: "Enlace no valido o expirado.",
        })
      );
    }
  };

  return (
    <>
      <Message />
      <div className="login">
        <NewPasswordForm
          handleOnChange={handleOnChange}
          handleOnSubmit={handleOnSubmit}
          data={data}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
