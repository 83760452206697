import { CartAddIcon } from "./Icons/CartAddIcon";

export const CartEmpty = ({ handleClick }) => {
  return (
    <div className="cart-empty">
      <CartAddIcon width={55} height={55} />
      <p>Aún no tienes productos en tu carrito.</p>
      <button onClick={handleClick}>Comenzar a comprar</button>
    </div>
  );
};
