import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Error, Loader } from "../../components";
import { ApprovedPayment } from "../../components/ApprovedPayment";
import { RejectedPayment } from "../../components/RejectedPayment";
import { PendingPayment } from "../../components/PendingPayment";
import { getStatusPaymentById } from "../../services";

export const PaymentStatus = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const collection_id = searchParams.get("collection_id");
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);

    getStatusPaymentById(collection_id)
      .then((res) => {
        if (isMounted.current) {
          setPaymentStatus(res);
        }
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      isMounted.current = false;
    };
  }, [collection_id]);

  return (
    <section className="payment-status">
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        Estado del pedido
      </div>

      <div className="col-12 anim" style={{ "--delay": ".5s" }}>
        <div className="payment-cart-status payment-summary">
          {isLoading && (
            <div className="payment-status-summary cart">
              <Loader />
            </div>
          )}

          {!isLoading && isError && (
            <div className="payment-status-summary cart">
              <Error message="Ha ocurrido un error al obtener la información del pago." />
            </div>
          )}

          {!isLoading && !isError && paymentStatus && (
            <>
              {paymentStatus.payment_data.status === "approved" && (
                <ApprovedPayment paymentStatus={paymentStatus} />
              )}

              {paymentStatus.payment_data.status === "rejected" && (
                <RejectedPayment paymentStatus={paymentStatus} />
              )}

              {paymentStatus.payment_data.status === "pending" && (
                <PendingPayment paymentStatus={paymentStatus} />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
