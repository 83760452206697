import { currencyFormater, imageUrl } from "../utils";

import blogDefaultThum1 from "../assets/img/banner/banner5.jpg";

export const FavoriteItem = ({
  id,
  title,
  thumb,
  price,
  discount_price,
  handleMoveToCart,
  handleRemoveFromFavorite,
}) => {
  return (
    <li className="cart-item">
      <div className="item-content">
        <img src={imageUrl(thumb ? thumb : blogDefaultThum1)} alt={title} />
        <div className="item-description">
          <small className="item-title">{title}</small>
          <small className="item-price">
            <del>{currencyFormater.format(price)}</del>
          </small>
          <span className="item-price item-discount-price">
            {currencyFormater.format(discount_price)}
          </span>
        </div>
      </div>
      <div className="item-actions">
        <button
          onClick={() => {
            handleMoveToCart && handleMoveToCart(id);
          }}
        >
          Mover al carrito
        </button>
        <button
          onClick={() => {
            handleRemoveFromFavorite && handleRemoveFromFavorite(id);
          }}
        >
          Quitar de la lista
        </button>
      </div>
    </li>
  );
};
