export const NoVisibleIcon = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="currentColor"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlnsserif="http://www.serif.com/"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <rect
          id="Icons"
          x="-960"
          y="-256"
          width="1280"
          height="800"
          style={{ fill: "none" }}
        ></rect>
        <g id="Icons1" serifid="Icons">
          <g id="Strike"> </g> <g id="H1"> </g> <g id="H2"> </g> <g id="H3"> </g>
          <g id="list-ul"> </g> <g id="hamburger-1"> </g> <g id="hamburger-2"> </g>
          <g id="list-ol"> </g> <g id="list-task"> </g> <g id="trash"> </g>
          <g id="vertical-menu"> </g> <g id="horizontal-menu"> </g> <g id="sidebar-2"> </g>
          <g id="Pen"> </g>
          <g id="Pen1" serifid="Pen"></g>
          <g id="clock"> </g> <g id="external-link"> </g> <g id="hr"> </g> <g id="info"> </g>
          <g id="warning"> </g> <g id="plus-circle"> </g> <g id="minus-circle"> </g>
          <g id="vue"> </g> <g id="cog"> </g> <g id="logo"> </g> <g id="radio-check"> </g>
          <g id="eye-slash">
            <path d="M13.673,10.345l-3.097,3.096l39.853,39.854l3.097,-3.097l-39.853,-39.853Z"></path>
            <path
              d="M17.119,19.984l2.915,2.915c-3.191,2.717 -5.732,6.099 -7.374,9.058l-0.005,0.01c4.573,7.646 11.829,14.872 20.987,13.776c2.472,-0.296 4.778,-1.141 6.885,-2.35l2.951,2.95c-4.107,2.636 -8.815,4.032 -13.916,3.342c-9.198,-1.244 -16.719,-8.788 -21.46,-17.648c2.226,-4.479 5.271,-8.764 9.017,-12.053Zm6.63,-4.32c2.572,-1.146 5.355,-1.82 8.327,-1.868c0.165,-0.001 2.124,0.092 3.012,0.238c0.557,0.092 1.112,0.207 1.659,0.35c8.725,2.273 15.189,10.054 19.253,17.653c-1.705,3.443 -3.938,6.398 -6.601,9.277l-2.827,-2.827c1.967,-2.12 3.622,-4.161 4.885,-6.45c0,0 -1.285,-2.361 -2.248,-3.643c-0.619,-0.824 -1.27,-1.624 -1.954,-2.395c-0.54,-0.608 -2.637,-2.673 -3.136,-3.103c-3.348,-2.879 -7.279,-5.138 -11.994,-5.1c-1.826,0.029 -3.582,0.389 -5.249,0.995l-3.127,-3.127Z"
              style={{ fillRule: "nonzero" }}
            ></path>
            <path d="M25.054,27.92l2.399,2.398c-0.157,0.477 -0.243,0.987 -0.243,1.516c0,2.672 2.169,4.841 4.841,4.841c0.529,0 1.039,-0.085 1.516,-0.243l2.399,2.399c-1.158,0.65 -2.494,1.02 -3.915,1.02c-4.425,0 -8.017,-3.592 -8.017,-8.017c0,-1.421 0.371,-2.756 1.02,-3.914Zm6.849,-4.101c0.049,-0.001 0.099,-0.002 0.148,-0.002c4.425,0 8.017,3.593 8.017,8.017c0,0.05 0,0.099 -0.001,0.148l-8.164,-8.163Z"></path>
          </g>
          <g id="eye"> </g> <g id="toggle-off"> </g> <g id="shredder"> </g>
          <g id="spinner--loading--dots-" serifid="spinner [loading, dots]"></g>
          <g id="react"> </g> <g id="check-selected"> </g> <g id="turn-off"> </g>
          <g id="code-block"> </g> <g id="user"> </g> <g id="coffee-bean"> </g>
          <g id="coffee-beans">
            <g id="coffee-bean1" serifid="coffee-bean"></g>
          </g>
          <g id="coffee-bean-filled"> </g>
          <g id="coffee-beans-filled">
            <g id="coffee-bean2" serifid="coffee-bean"></g>
          </g>
          <g id="clipboard"> </g> <g id="clipboard-paste"> </g> <g id="clipboard-copy"> </g>
          <g id="Layer1"> </g>
        </g>
      </g>
    </svg>
  );
};
