import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { allCourseAdvance, setMessage } from '../store/slices';
import { useDispatch } from 'react-redux';
import { ROLES } from '../utils';

export function useUserAdvance({ user }) {
  const [error, setError] = useState(false);
  const { idCourse } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.roleId !== ROLES.user) return;

    dispatch(allCourseAdvance(idCourse)).catch((err) => {
      if (err.response.status === 401) return;

      setError(true);

      dispatch(
        setMessage({
          type: 'error',
          message: 'No fue posible recuperar el avance del curso, porfavor inténtelo nuevamente',
        })
      );
    });
  }, [user, idCourse, dispatch]);

  return { error };
}
