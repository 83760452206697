export { CourseDetails } from "./app/course-details/CourseDetails";
export { Dashboard } from "./app/dashboard/Dashboard";
export { PaymentStatus } from "./app/PaymentStatus";
export { Service } from "./app/service/Service";
export { Courses } from "./app/courses/Courses";
export { Course } from "./app/course/Course";
export { Wishlist } from "./app/Wishlist";
export { Support } from "./app/Support";
export { Profile } from "./app/Profile";
export { Exam } from "./app/exam/Exam";
export { Error404 } from "./Error404";
export { Faqs } from "./app/Faqs";
export { Cart } from "./app/Cart";
export { LogIn } from "./LogIn";
export { App } from "./app/App";
export { Recover } from "./Recover";
