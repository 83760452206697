export const ServicesIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99291 2.0293L1.35938 4.95215L3.73914 6.18716L6.99291 7.875L10.2451 6.18716L12.6249 4.95215L6.99291 2.0293Z"
        stroke="var(--icon-color)"
        strokeWidth=".75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1551 6.23291L6.99311 7.87484L3.82959 6.23291V7.98409L6.99311 9.62443L10.2453 7.93659V6.28041L10.1551 6.23291Z"
        stroke="var(--icon-color)"
        strokeWidth=".75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.82959 9.42518V6.23315L6.64636 4.77173"
        stroke="var(--icon-color)"
        strokeWidth=".75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
