import React from 'react'
import { Spinner } from 'react-bootstrap'

export function Loader({ delay }) {
  return (
    <div className='row mx-0 my-5 anim' style={{ '--delay': `${delay}s` }}>
      <Spinner animation="border" variant="warning" className='m-auto' />
    </div>
  )
}
