import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../../../utils";
import { useCourse, useUserAdvance } from "../../../hooks";
import { CourseProgram, CourseProgress } from "./components";
import { Error, CourseItemModal, Loader } from "../../../components";
import { closeModalSlideshow, closeModalVideo } from "../../../store/slices";

export const Course = () => {
  const user = useSelector((state) => state.user);
  const { isOpen: isModalVideoOpen, videoId } = useSelector((state) => state.app.modalVideo);
  const { isOpen: isModalSlideshowOpen, slideId } = useSelector(
    (state) => state.app.modalSlideshow
  );
  const { loading, error, course, courseAdvance } = useCourse({ user, isProtected: true });
  const { error: errorAdvance } = useUserAdvance({ user });

  const dispatch = useDispatch();

  if (loading) return <Loader delay={0} />;

  if (error || errorAdvance) return <Error />;

  return (
    <>
      <div className="main-header anim" style={{ "--delay": "0s" }}>
        {course?.title}
      </div>

      <div className='container-fluid'>
        {user.roleId !== ROLES.admin && <CourseProgress user={user} course={course} courseAdvance={courseAdvance} />}
      </div>

      <div className="container-fluid">
        <br />
        <div className="row program-container flag-loaded">
          <div className="col-6 col-sm-5">
            <h4 className="article-title anim" style={{ "--delay": ".3s" }}>
              Programa
            </h4>
          </div>
        </div>
        <br />

        <div className="row program-container flag-loaded">
          <CourseProgram course={course} />
        </div>
      </div>

      <CourseItemModal
        slideID={videoId}
        show={isModalVideoOpen}
        onHide={() => dispatch(closeModalVideo())}
      />

      <CourseItemModal
        slideID={slideId}
        show={isModalSlideshowOpen}
        onHide={() => dispatch(closeModalSlideshow())}
      />
    </>
  );
};
