import { Link } from 'react-router-dom'

export const ContactInfoCard = () => {
  return (
    <>
      <div className='text-white contact-info-bx'>
        <div className='heading-bx left mb-4'>
          <h3 className='m-b10 title-head'>Información de <span>Contacto</span></h3>
          <p className='m-b0'>En PS ProcesoSeguro buscamos satisfacer las necesidades del mercado en Seguridad e Higiene Industrial, Salud Laboral, Protección Ambiental y Legal, brindando soluciones innovadoras y tecnológicas, con sentido en la productividad, prevención y mejora continua.</p>
        </div>
        <div className='widget widget_getintuch'>
          <ul>
            <li><i className='ti-thumb-up' />Proceso Seguro S.C.</li>
            <li><i className='ti-location-pin' />C.Ramon Rodriguez Familiar 20 B, San Javier, 76020 Santiago de Querétaro, Qro.</li>
            <li><i className='ti-mobile' />800-001-1911 (24/7)</li>
            <li><i className='ti-email' />contacto@procesoseguro.com</li>
          </ul>
        </div>
        <h5 className='m-t0 m-b20'>Siguenos en</h5>
        <ul className='list-inline contact-social-bx m-b0'>
          <li><Link to='#' className='btn outline radius-xl'><i className='fa fa-facebook' /></Link></li>
          <li><Link to='#' className='btn outline radius-xl'><i className='fa fa-twitter' /></Link></li>
          <li><Link to='#' className='btn outline radius-xl'><i className='fa fa-linkedin' /></Link></li>
          <li><Link to='#' className='btn outline radius-xl'><i className='fa fa-google-plus' /></Link></li>
        </ul>
      </div>
    </>
  )
}
