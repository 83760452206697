import { imgCourseDefault } from '../assets/img/courses';

export const imageUrl = (url) => {
  if (!url) return imgCourseDefault;
  if (url.includes('https://drive.google.com')) {
    const idDriveImage = url.match(/[\w-]+(?=\/view)/i)?.[0];
    return url.replace(url, `http://drive.google.com/uc?export=view&id=${idDriveImage}`);
  }
  return url;
};
