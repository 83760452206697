export const LogoutIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="var(--icon-color)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.075 11.9166C11.7777 11.9166 11.5367 12.1529 11.5367 12.4444V15.6111H5.07667V5.05556H11.5367V8.22222C11.5367 8.5137 11.7777 8.74998 12.075 8.74998C12.3723 8.74998 12.6133 8.5137 12.6133 8.22222V4.52777C12.6133 4.23629 12.3723 4 12.075 4H4.53832C4.24101 4 4 4.23629 4 4.52777V16.1388C4 16.4303 4.24101 16.6666 4.53832 16.6666H12.075C12.3723 16.6666 12.6133 16.4303 12.6133 16.1389V12.4444C12.6133 12.1529 12.3723 11.9166 12.075 11.9166Z"
        fill="var(--icon-color)"
      />
      <path
        d="M16.543 10.6865C16.5482 10.68 16.5527 10.6732 16.5576 10.6665C16.5633 10.6586 16.5693 10.6509 16.5747 10.6427C16.5799 10.6347 16.5844 10.6265 16.5892 10.6183C16.5935 10.6109 16.5981 10.6036 16.6021 10.5959C16.6065 10.5876 16.6102 10.579 16.6141 10.5706C16.6178 10.5626 16.6217 10.5547 16.6251 10.5465C16.6284 10.5383 16.6311 10.5299 16.634 10.5215C16.6371 10.5127 16.6404 10.504 16.6431 10.495C16.6455 10.4867 16.6474 10.4782 16.6494 10.4697C16.6517 10.4605 16.6542 10.4515 16.656 10.4422C16.6579 10.4324 16.659 10.4225 16.6605 10.4126C16.6616 10.4045 16.6631 10.3965 16.6639 10.3883C16.6674 10.3517 16.6674 10.3148 16.6639 10.2782C16.6631 10.27 16.6616 10.262 16.6605 10.2539C16.6591 10.244 16.6579 10.2341 16.656 10.2243C16.6542 10.215 16.6517 10.206 16.6494 10.1968C16.6474 10.1883 16.6455 10.1798 16.6431 10.1715C16.6404 10.1625 16.6371 10.1538 16.634 10.145C16.6311 10.1366 16.6284 10.1282 16.6251 10.12C16.6217 10.1118 16.6178 10.1039 16.6141 10.0959C16.6102 10.0875 16.6065 10.0789 16.6021 10.0706C16.5981 10.0629 16.5935 10.0556 16.5892 10.0482C16.5844 10.04 16.5799 10.0318 16.5747 10.0238C16.5693 10.0156 16.5633 10.0079 16.5576 9.99999C16.5527 9.99332 16.5481 9.98651 16.543 9.98003C16.5317 9.96605 16.5199 9.95259 16.5073 9.93979C16.5072 9.93958 16.507 9.93938 16.5068 9.93919L14.3242 7.70987C14.1111 7.49222 13.7657 7.49222 13.5526 7.70987C13.3395 7.92751 13.3395 8.28041 13.5526 8.49806L14.8037 9.77596H10.1189C9.81753 9.77596 9.57324 10.0255 9.57324 10.3333C9.57324 10.6411 9.81753 10.8906 10.1189 10.8906H14.8037L13.5526 12.1685C13.3395 12.3862 13.3395 12.7391 13.5526 12.9567C13.7657 13.1744 14.1111 13.1744 14.3242 12.9567L16.5068 10.7274C16.507 10.7272 16.5072 10.727 16.5073 10.7268C16.5199 10.7139 16.5317 10.7004 16.543 10.6865Z"
        fill="var(--icon-color)"
      />
    </svg>
  );
};
