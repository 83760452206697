import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { getCourseRegister } from '../../../services';
import { CertificatePDF } from '../../../components/CertificatePDF';
import './styles.css';

export const Certificado = ({ handleShowCertificate }) => {
  const { user, course, courseAdvance } = useSelector((state) => state);
  const [register, setRegister] = useState(null);

  useEffect(() => {
    if (!course) return;

    let isMounted = true;

    getCourseRegister(course?.course?.id)
      .then((register) => {
        if (isMounted) setRegister(register);
      })

    return () => {
      isMounted = false;
    };
  }, [user, course]);

  return (
    <div className='pdf-overlay'>
      <div className='pdf'>
        <button
          type='button'
          className='btn btn-warning btn-small btn-toggle-pdf'
          onClick={handleShowCertificate}
        >
          Cerrar PDF
        </button>

        {register && (
          <PDFViewer className='pdf-viewer'>
            <CertificatePDF
              user={user}
              course={course}
              courseAdvance={courseAdvance}
              register={register}
            />
          </PDFViewer>
        )}
      </div>
    </div>
  );
};
